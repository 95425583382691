import { Box } from '@mui/material'
import React from 'react'
import ProductCommonFooter from '../product/ProductCommonFooter'

interface CompanyFooterProps {
  id?: string
  isShow?: boolean
}

const CompanyFooter: React.FC<CompanyFooterProps> = ({
  id,
  isShow,
}: CompanyFooterProps) => {
  return (
    <Box
      width={'100%'}
      position={'relative'}
      bottom={0}
      height={isShow ? 'fit-content' : '0px'}
      overflow={'hidden'}
      maxHeight={'400px'}
    >
      <ProductCommonFooter isShow={isShow} />
    </Box>
  )
}

export default CompanyFooter
