// AnimatedFloatingCard.tsx
import React, { useRef } from 'react'
import { Card, CardProps } from '@mui/material'
import { keyframes } from '@mui/system'
import { styled } from '@mui/material/styles'

const FloatingCard = styled(Card)`
  position: relative;
  max-width: 70%;
  width: 100%;
  padding: 1rem;
  box-sizing: border-box;
  z-index: 1000;
  top: 16px;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 8px;
  background-color: transparent;
  background-blur: 16px;
  color: white;
  border: 1px solid rgba(255, 255, 255, 0.4);
`

const bounceAndEnlarge = keyframes`
  0%, 20%, 50%, 80%, 100% {
    transform: translateX(-50%) translateY(0) scale(1);
  }
  40% {
    transform: translateX(-50%) translateY(-10px) scale(1);
  }
  60% {
    transform: translateX(-50%) translateY(-5px) scale(1);
  }
  90% {
    transform: translateX(-50%) translateY(0) scale(1.05);
  }
  100% {
    transform: translateX(-50%) translateY(0) scale(1.2);
  }
`

const enlarge = keyframes`
  0% {
    transform: translateX(-50%) translateY(0) scale(1);
  }
  100% {
    transform: translateX(-50%) translateY(0) scale(1.2);
  }
`

const bounceAndShrink = keyframes`
  0% {
    transform: translateX(-50%) translateY(0) scale(1.2);
  }
  100% {
    transform: translateX(-50%) translateY(0) scale(1);
  }
`

const StyledFloatingCard = React.forwardRef<HTMLDivElement, CardProps>(
  (props, ref) => (
    <FloatingCard
      ref={ref}
      {...props}
      sx={{
        '&.hover': {
          animation: `${enlarge} 1.5s ease-out forwards`,
        },
        '&.not-hover': {
          animation: `${bounceAndShrink} 1s ease-out forwards`,
        },
      }}
    />
  ),
)

StyledFloatingCard.displayName = 'StyledFloatingCard'

const AnimatedFloatingCard: React.FC<CardProps> = (props) => {
  const [isHovering, setIsHovering] = React.useState(false)
  const cardRef = useRef<HTMLDivElement>(null)

  const handleMouseEnter = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation()
    if (!cardRef.current) return
    console.log('Animated FloatingCard mouse enter', cardRef.current)
    if (!isHovering) {
      console.log('Animated FloatingCard mouse set isHovering to true')
      setIsHovering(true)
      return
    }
    const rect = cardRef.current.getBoundingClientRect()
    const isInCenter =
      e.clientX > rect.left + rect.width * 0.15 &&
      e.clientX < rect.right - rect.width * 0.15 &&
      e.clientY > rect.top + rect.height * 0.15 &&
      e.clientY < rect.bottom - rect.height * 0.15
    setIsHovering((true && isInCenter) || !isHovering)
  }

  const handleMouseLeave = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation()
    if (!cardRef.current) return
    console.log('Animated FloatingCard mouse leave', cardRef.current)
    const rect = cardRef.current.getBoundingClientRect()
    const isInCenter =
      e.clientX > rect.left + rect.width * 0.15 &&
      e.clientX < rect.right - rect.width * 0.15 &&
      e.clientY > rect.top + rect.height * 0.15 &&
      e.clientY < rect.bottom - rect.height * 0.15
    setIsHovering(false || isInCenter)
  }

  return (
    <StyledFloatingCard
      {...props}
      ref={cardRef}
      className={isHovering ? 'hover' : 'not-hover'}
      // onMouseEnter={handleMouseEnter}
      // onMouseLeave={handleMouseLeave}
    />
  )
}

export default AnimatedFloatingCard
