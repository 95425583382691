// CompanyHeader.tsx
import React from 'react'
import {
  AppBar,
  MenuItem,
  Toolbar,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import { styled } from '@mui/system'
import { Link } from 'react-router-dom'
import SectionButton from '../../component/button/SectionButton'

const Logo = styled('img')({
  height: '80px',
  width: 'auto',
  paddingRight: '16px',
})

const CompanyHeader: React.FC = () => {
  const theme = useTheme()
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <AppBar
      position='fixed'
      sx={{ background: 'transparent', backdropFilter: 'blur(10px)' }}
    >
      <Toolbar>
        <Link to='/'>
          {/* <Logo src='/images/logo/logo_b_n.png' alt='Company Name' height={'80px'} /> */}
          <Logo
            src='/images/logo/white-font-logo.png'
            alt='Company Name'
            height={'80px'}
          />
        </Link>
        <Typography
          variant='h6'
          component='div'
          sx={{ flexGrow: 1 }}
        ></Typography>
        {!isSmallScreen ? (
          <>
            <SectionButton
              title='Whatever Works'
              link='/?focus=companyStartCard'
              color='white'
              type='Link'
            />
            <SectionButton
              title='Home'
              link='/?focus=companyInfoCard'
              type='Link'
              color='white'
            />
            <SectionButton
              title='About'
              link='/?focus=companySloganCard'
              type='Link'
              color='white'
            />
            <SectionButton
              title='Gallery'
              link='/?focus=companyGalleryCard'
              type='Link'
              color='white'
            />
            <SectionButton
              title='Contact'
              link='/?focus=companyContactCard'
              type='Link'
              color='white'
            />
          </>
        ) : (
          <SectionButton
            title='Menu'
            type='Dropdown'
            color='white'
            backgroundColor='transparent'
          >
            <MenuItem>
              <SectionButton
                title='Home'
                link='/?focus=companyInfoCard'
                type='Link'
                color='white'
              />
            </MenuItem>
            <MenuItem>
              <SectionButton
                title='About'
                link='/?focus=companySloganCard'
                type='Link'
                color='white'
              />
            </MenuItem>
            <MenuItem>
              <SectionButton
                title='Gallery'
                link='/?focus=companyGalleryCard'
                type='Link'
                color='white'
              />
            </MenuItem>
            <MenuItem>
              <SectionButton
                title='Contact'
                link='/?focus=companyContactCard'
                type='Link'
                color='white'
              />
            </MenuItem>
          </SectionButton>
        )}
      </Toolbar>
    </AppBar>
  )
}

export default CompanyHeader
