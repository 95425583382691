import React, { useEffect, useRef } from 'react'

type UseEventListenerProps<T extends Event> = {
  eventName: string
  handler: (event: T) => void
  element?: HTMLElement | Document | null
}

function useEventListener<T extends Event>({
  eventName,
  handler,
  element = document,
}: UseEventListenerProps<T>) {
  const savedHandler = useRef<(event: T) => void>()

  useEffect(() => {
    savedHandler.current = handler
  }, [handler])

  useEffect(() => {
    const isSupported = element && element.addEventListener
    if (!isSupported) return

    const eventListener = (event: T) => savedHandler.current?.(event)

    element.addEventListener(eventName, eventListener as EventListener)

    return () => {
      element.removeEventListener(eventName, eventListener as EventListener)
    }
  }, [eventName, element])
}

type DotProps = {
  color: string
  size: number
  borderWidth?: number
  alpha?: number
}

const Dot = React.memo(
  React.forwardRef<HTMLDivElement, DotProps>(
    ({ color, size, borderWidth, alpha }, ref) => {
      const style: React.CSSProperties = {
        width: `${size}px`,
        height: `${size}px`,
        borderRadius: '50%',
        position: 'fixed',
        zIndex: 2147483647,
        pointerEvents: 'none',
        mixBlendMode: 'difference',
        transition: 'transform 0.15s ease-out',
        left: `-${size / 2 + (borderWidth || 0)}px`,
        top: `-${size / 2 + (borderWidth || 0)}px`,
      }

      if (borderWidth) {
        style.border = `${borderWidth}px solid rgba(${color}, ${alpha})`
      } else {
        style.backgroundColor = `rgba(${color}, 1)`
      }

      return <div ref={ref} style={style} />
    },
  ),
)

type RedDotCursorProps = {
  color?: string
  outerAlpha?: number
  innerSize?: number
  outerSize?: number
  outerScale?: number
  innerScale?: number
}

const RedDotCursor: React.FC<RedDotCursorProps> = ({
  color = '242, 116, 87',
  outerAlpha = 0.4,
  innerSize = 8,
  outerSize = 8,
  outerScale = 5,
  innerScale = 0.7,
}) => {
  const borderWidth = 2
  const cursorInnerRef = useRef<HTMLDivElement>(null)
  const cursorOuterRef = useRef<HTMLDivElement>(null)

  const onMouseMove = (e: MouseEvent) => {
    const { clientX, clientY } = e
    if (cursorOuterRef.current && cursorInnerRef.current) {
      cursorOuterRef.current.style.transform = `translate(${clientX}px, ${clientY}px)`
      cursorInnerRef.current.style.transform = `translate(${clientX}px, ${clientY}px)`
    }
  }

  const onMouseDown = (e: MouseEvent) => {
    const { clientX, clientY } = e
    if (cursorOuterRef.current && cursorInnerRef.current) {
      cursorInnerRef.current.style.transform = `translate(${clientX}px, ${clientY}px) scale(${innerScale})`
      cursorOuterRef.current.style.transform = `translate(${clientX}px, ${clientY}px) scale(${outerScale})`
    }
  }

  const onMouseUp = (e: MouseEvent) => {
    const { clientX, clientY } = e
    if (cursorOuterRef.current && cursorInnerRef.current) {
      cursorOuterRef.current.style.transform = `translate(${clientX}px, ${clientY}px) scale(1)`
      cursorInnerRef.current.style.transform = `translate(${clientX}px, ${clientY}px) scale(1)`
    }
  }

  useEventListener<MouseEvent>({ eventName: 'mousemove', handler: onMouseMove })
  useEventListener<MouseEvent>({ eventName: 'mousedown', handler: onMouseDown })
  useEventListener<MouseEvent>({ eventName: 'mouseup', handler: onMouseUp })

  return (
    <>
      <Dot ref={cursorInnerRef} color={color} size={innerSize} />
      <Dot
        ref={cursorOuterRef}
        color={color}
        size={outerSize}
        borderWidth={borderWidth}
        alpha={outerAlpha}
      />
    </>
  )
}

export default RedDotCursor
