// MainParentFrame.tsx
import React, { ReactNode, useEffect, useRef, useState } from 'react'
import { styled } from '@mui/system'
import { useLocation } from 'react-router-dom'

interface Props {
  children: ReactNode
  isDarkMode: boolean
  gradientColors?: string[]
}

const gradientColorsDefault = ['#3a7bd5', '#3a6073', '#ff5f6d', '#ffc371']

const MainParentFrame = styled('div', {
  shouldForwardProp: (prop) =>
    prop !== 'isDarkMode' && prop !== 'gradientColors',
})<Props>(({ theme, isDarkMode, gradientColors = gradientColorsDefault }) => {
  const gradient = `linear-gradient(135deg, ${gradientColors.join(', ')})`

  return {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    // bottom: 0,
    // marginBottom: '300px',
    overflowY: 'scroll',
    overflowX: 'hidden',
    backgroundSize: '200% 200%',
    backgroundColor: isDarkMode ? '#212121' : '#f5f5f5',
    backgroundImage: gradient,
    cursor: 'none',
    animation: 'gradientAnimation 10s ease infinite',
    '&::-webkit-scrollbar': {
      width: '0px',
      background: theme.palette.background.default,
    },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: '4px',
      background: theme.palette.primary.main,
    },
    '@keyframes gradientAnimation': {
      '0%': {
        backgroundPosition: '0% 50%',
      },
      '50%': {
        backgroundPosition: '100% 50%',
      },
      '100%': {
        backgroundPosition: '0% 50%',
      },
    },
  }
})

const ChildrenWrapper = styled('div')({
  marginTop: '100px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  justifyContent: 'flex-start',
  gap: '20px',
})

const MainParentFrameWrapper: React.FC<Props> = (props) => {
  const location = useLocation()
  const childrenWrapperRef = useRef<HTMLDivElement | null>(null)
  const [scrollRatio, setScrollRatio] = useState(0)

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search)
    const targetId = searchParams.get('focus')

    if (targetId && childrenWrapperRef.current) {
      const targetElement = childrenWrapperRef.current.querySelector(
        `#${targetId}`,
      )

      if (targetElement) {
        const targetHTMLElement = targetElement as HTMLElement
        targetHTMLElement.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
        })
        targetHTMLElement.focus({ preventScroll: true })
      }
    }
  }, [location.search])

  return (
    <MainParentFrame {...props}>
      {/* <ChildrenWrapper ref={childrenWrapperRef}>
        {React.Children.map(props.children, (child, index) => (
          <div id={`child-${index}`}>{child}</div>
        ))}
      </ChildrenWrapper> */}
      <ChildrenWrapper ref={childrenWrapperRef}>
        {props.children}
      </ChildrenWrapper>
    </MainParentFrame>
  )
}

export default MainParentFrameWrapper
