import { Box, BoxProps, Button, Divider, Grid, Typography } from '@mui/material'
import React, { ReactNode } from 'react'
import ProductCommonHeader from '../ProductCommonHeader'
import ProductCommonFooter from '../ProductCommonFooter'
import {
  FadeInWhenVisibleBox,
  FadeInWhenVisibleBoxAnimationType,
} from '../../../component/common/FadeInWhenVisibleBox'
import PriceTypeToggle, {
  PriceTypeToggleRef,
} from '../../../component/toggle/PriceTypeToggle'
import { useSpring, animated } from 'react-spring'
import Snowfall from 'react-snowfall'

const SelfHostSolutionMainPage: React.FC = () => {
  // Local variables
  const priceTypeOptions = ['Monthly', 'Yearly']

  // Local ref
  const priceTypeToggleRef = React.useRef<PriceTypeToggleRef>(null)

  // Local state
  const [priceType, setPriceType] = React.useState<string>(priceTypeOptions[0])
  const [yourContributionShowOptional, setYourContributionShowOptional] =
    React.useState<boolean>(false)

  // Local style
  const animationProps = useSpring({
    opacity: yourContributionShowOptional ? 1 : 0,
    transform: yourContributionShowOptional
      ? 'translateY(0)'
      : 'translateY(-20px)',
    config: { tension: 220, friction: 120 },
  })

  return (
    <>
      <Snowfall />
      <ProductCommonHeader />
      <Box
        id={'main-box'}
        color={'white'}
        sx={{
          width: '100%',
          height: '100%',
          minHeight: '100vh',
          background: 'transparent',
          backgroundImage: 'url(./images/common/background-04.png)',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          maxWidth: '100%',
          backgroundAttachment: 'fixed',
          overflowX: 'hidden',
        }}
        display={'flex'}
        flexDirection={'column'}
        justifyContent={'flex-start'}
        alignItems={'flex-start'}
        gap={10}
      >
        <Box
          width={'100%'}
          height={'83px'}
          justifyContent={'center'}
          alignItems={'center'}
          maxWidth={'100%'}
        ></Box>
        <FadeInWhenVisibleBox
          width={'100%'}
          maxWidth={'100%'}
          display={'flex'}
          flexDirection={'column'}
          justifyContent={'center'}
          alignItems={'center'}
        >
          <Box
            width={'100%'}
            maxWidth={'80%'}
            height={'auto'}
            display={'flex'}
            flexDirection={'column'}
            justifyContent={'center'}
            alignItems={'center'}
            paddingTop={'2em'}
            paddingBottom={'2em'}
          >
            <Typography variant='h3' component='h1' gutterBottom align='center'>
              Customized Self Host Service for Your Business
            </Typography>
            <Divider
              variant='middle'
              style={{ width: '50%', marginBottom: '1em' }}
            />

            <Box
              width={'100%'}
              maxWidth={'100%'}
              height={'auto'}
              padding={'24px'}
            >
              <Grid container spacing={2}>
                <Grid item xs={12} sm={4}>
                  <FadeInWhenVisibleBox>
                    <Typography
                      variant='h6'
                      component='p'
                      align='left'
                      style={{
                        maxWidth: '80%',
                        margin: 'auto',
                        fontWeight: 'bold',
                      }}
                    >
                      {`01 Today's Landscape`}
                    </Typography>
                    <Typography
                      variant='subtitle1'
                      component='p'
                      align='left'
                      style={{
                        maxWidth: '80%',
                        margin: 'auto',
                        lineHeight: '1.2',
                      }}
                    >
                      {`In today's dynamic digital environment, the need for self-hosted solutions is increasingly clear.
                      Your business is unique, and you need a software solution that is as flexible and independent as you are.
                      This is where our expertise comes into play.`}
                    </Typography>
                  </FadeInWhenVisibleBox>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <FadeInWhenVisibleBox>
                    <Typography
                      variant='h6'
                      component='p'
                      align='left'
                      style={{
                        maxWidth: '80%',
                        margin: 'auto',
                        fontWeight: 'bold',
                      }}
                    >
                      {`02  We Can Empower You`}
                    </Typography>
                    <Typography
                      variant='subtitle1'
                      component='p'
                      align='left'
                      style={{
                        maxWidth: '80%',
                        margin: 'auto',
                        lineHeight: '1.2',
                      }}
                    >
                      {`We specialize in providing self-hosted solutions, offering you complete control and customization to match your business needs.
                      Our team of experienced IT professionals and project managers work closely with you, understanding your business,
                      its goals, and its challenges, to deliver a bespoke self-hosted setup.`}
                    </Typography>
                  </FadeInWhenVisibleBox>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <FadeInWhenVisibleBox>
                    <Typography
                      variant='h6'
                      component='p'
                      align='left'
                      style={{
                        maxWidth: '80%',
                        margin: 'auto',
                        fontWeight: 'bold',
                      }}
                    >
                      {`03  We Facilitate`}
                    </Typography>
                    <Typography
                      variant='subtitle1'
                      component='p'
                      align='left'
                      style={{
                        maxWidth: '80%',
                        margin: 'auto',
                        lineHeight: '1.2',
                      }}
                    >
                      {`Whether you need a self-hosted DNS configuration, an independent domain service, a secure static webpage host, or a private cloud server,
                      we've got you covered. Our self-hosted solutions are designed to streamline your digital operations, enhance productivity,
                      and drive growth while ensuring data sovereignty.`}
                    </Typography>
                  </FadeInWhenVisibleBox>
                </Grid>
              </Grid>
            </Box>
            <Box
              width={'100%'}
              maxWidth={'100%'}
              height={'auto'}
              padding={'24px'}
            >
              <Grid
                container
                spacing={2}
                alignItems={'center'}
                justifyContent={'center'}
              >
                <Grid item xs={12} sm={4}>
                  <FadeInWhenVisibleBox>
                    <Typography
                      variant='h6'
                      component='p'
                      align='left'
                      style={{
                        maxWidth: '80%',
                        margin: 'auto',
                        fontWeight: 'bold',
                      }}
                    >
                      {`04  We Engineer`}
                    </Typography>
                    <Typography
                      variant='subtitle1'
                      component='p'
                      align='left'
                      style={{
                        maxWidth: '80%',
                        margin: 'auto',
                        lineHeight: '1.2',
                      }}
                    >
                      {`We leverage the latest technologies and adhere to industry best practices to ensure that your self-hosted services are reliable,
                      secure, and user-friendly. Moreover, our solutions are flexible and scalable, designed to evolve with your business.`}
                    </Typography>
                  </FadeInWhenVisibleBox>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <FadeInWhenVisibleBox>
                    <Typography
                      variant='h6'
                      component='p'
                      align='left'
                      style={{
                        maxWidth: '80%',
                        margin: 'auto',
                        fontWeight: 'bold',
                      }}
                    >
                      {`05  We Deliver`}
                    </Typography>
                    <Typography
                      variant='subtitle1'
                      component='p'
                      align='left'
                      style={{
                        maxWidth: '80%',
                        margin: 'auto',
                        lineHeight: '1.2',
                      }}
                    >
                      {`Don't settle for third-party hosted services that compromise your control and customization. Opt for a self-hosted solution that's engineered for your business. Contact us today to discover how we can revolutionize your business operations with our tailor-made self-hosted solutions.`}
                    </Typography>
                  </FadeInWhenVisibleBox>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </FadeInWhenVisibleBox>

        <FadeInWhenVisibleBox
          width={'fill-available'}
          height={'fit-content'}
          display={'flex'}
          flexDirection={'row'}
          justifyContent={'center'}
          alignItems={'center'}
          gap={'24px'}
          animationType={FadeInWhenVisibleBoxAnimationType.ZoomIn}
          boxShadow={15}
          margin={'24px'}
          padding={'24px'}
          paddingY={'48px'}
        >
          <FadeInWhenVisibleBox minWidth={'50%'}>
            <Typography
              variant='h1'
              component='h1'
              gutterBottom
              align='center'
              fontWeight={'bolder'}
            >
              Why us?
            </Typography>
          </FadeInWhenVisibleBox>
          <FadeInWhenVisibleBox>
            <Typography
              variant='subtitle1'
              component='p'
              align='left'
              maxWidth={'80%'}
              lineHeight={1.2}
            >
              {`We understand that every business is unique, especially if you're a startup or an SME.
            That's why we've pioneered an innovative pricing model that puts your needs first.`}
            </Typography>
            <br />
            <Typography
              variant='subtitle1'
              component='p'
              align='left'
              maxWidth={'80%'}
              lineHeight={1.2}
            >
              {`Unlike traditional software development companies, we don't charge you for the
            development of your software solution. Instead, our pricing is based solely on your
            usage. This means you only pay for what you need, when you need it.`}
            </Typography>
            <br />
            <Typography
              variant='subtitle1'
              component='p'
              align='left'
              maxWidth={'80%'}
              lineHeight={1.2}
            >
              {`With us, you can focus on what you do best: running your business. We handle all the
            technical aspects for you, from maintaining and updating the software, to ensuring its
            security and scalability. We've got you covered, so you can rest easy knowing your
            software is in expert hands.`}
            </Typography>
          </FadeInWhenVisibleBox>
        </FadeInWhenVisibleBox>

        <FadeInWhenVisibleBox
          width={'fill-available'}
          height={'fit-content'}
          display={'flex'}
          flexDirection={'column'}
          justifyContent={'center'}
          alignItems={'center'}
          animationType={FadeInWhenVisibleBoxAnimationType.ZoomIn}
          boxShadow={15}
          margin={'24px'}
          padding={'24px'}
        >
          <Typography variant='h4' component='h1' gutterBottom align='center'>
            What we offer
          </Typography>
          <Divider
            variant='middle'
            style={{ width: '50%', marginBottom: '1em' }}
          />

          <Box
            width={'80%'}
            display={'flex'}
            flexDirection={'row'}
            flexWrap='wrap'
            gap={3}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <Box width={'100%'}>
                  <Typography
                    variant='h6'
                    component='h1'
                    gutterBottom
                    align='left'
                  >
                    Your Business, We Build
                  </Typography>
                  <Divider
                    variant='fullWidth'
                    style={{ width: '100%', marginBottom: '1em' }}
                  />
                  <Typography
                    variant='subtitle1'
                    component='h1'
                    gutterBottom
                    align='left'
                    fontWeight={'bold'}
                  >
                    {'\u2022'} Custom Domain Name Services
                  </Typography>
                  <Typography
                    variant='subtitle1'
                    component='h1'
                    gutterBottom
                    align='left'
                    fontWeight={'bold'}
                  >
                    {'\u2022'} Website Hosting and Maintenance
                  </Typography>
                  <Typography
                    variant='subtitle1'
                    component='h1'
                    gutterBottom
                    align='left'
                    fontWeight={'bold'}
                  >
                    {'\u2022'} Managed IT Services
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Box width={'100%'} color={'white'}>
                  <Typography
                    variant='h6'
                    component='h1'
                    gutterBottom
                    align='left'
                    fontWeight={'bold'}
                  >
                    Our Extra Commitment
                  </Typography>
                  <Divider
                    variant='fullWidth'
                    style={{ width: '100%', marginBottom: '1em' }}
                  />
                  <Typography
                    variant='subtitle1'
                    component='h1'
                    gutterBottom
                    align='left'
                    fontWeight={'bold'}
                  >
                    {'\u2022'} Demo (If available)
                  </Typography>
                  <Typography
                    variant='subtitle1'
                    component='h1'
                    gutterBottom
                    align='left'
                    fontWeight={'bold'}
                  >
                    {'\u2022'} Custom IT Consultant Services
                  </Typography>
                  <Typography
                    variant='subtitle1'
                    component='h1'
                    gutterBottom
                    align='left'
                    fontWeight={'bold'}
                  >
                    {'\u2022'} Technical support
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Box>
          <br />
          <br />
          <Box
            width={'80%'}
            display={'flex'}
            flexDirection={'row'}
            flexWrap='wrap'
            gap={3}
          >
            <Box
              width={'100%'}
              display={'flex'}
              flexDirection={'column'}
              alignItems={'center'}
            >
              <Button
                variant='outlined'
                color='inherit'
                onClick={() => {
                  window.location.href = 'mailto:hello@whateverworks.cc'
                }}
              >
                Contact Us
              </Button>
            </Box>
          </Box>
        </FadeInWhenVisibleBox>

        <FadeInWhenVisibleBox
          width={'fill-available'}
          height={'fit-content'}
          display={'none'}
          flexDirection={'column'}
          justifyContent={'center'}
          alignItems={'center'}
          animationType={FadeInWhenVisibleBoxAnimationType.SlideLeft}
          boxShadow={15}
          margin={'24px'}
          padding={'24px'}
        >
          <Typography variant='h4' component='h1' gutterBottom align='center'>
            Pricing
          </Typography>
          <Divider
            variant='middle'
            style={{ width: '50%', marginBottom: '1em' }}
          />
          <Box
            width={'60%'}
            display={'flex'}
            flexDirection={'row'}
            justifyContent={'center'}
            alignItems={'center'}
          >
            <PriceTypeToggle
              ref={priceTypeToggleRef}
              options={priceTypeOptions}
              onChange={(type) => {
                setPriceType(type)
              }}
            />
          </Box>

          <Box
            width={'80%'}
            display={'flex'}
            flexDirection={'row'}
            justifyContent={'center'}
            alignItems={'center'}
          >
            <Grid
              container
              spacing={2}
              gap={5}
              display={'flex'}
              justifyContent={'center'}
              alignItems={'center'}
            >
              {/* Essential Price Grid and Box */}
              <Grid item xs={12} sm={4}>
                <Box
                  width={'100%'}
                  padding={'24px'}
                  boxShadow={15}
                  borderRadius={'50px'}
                  display={'flex'}
                  flexDirection={'column'}
                >
                  <Typography
                    variant='h6'
                    component='h1'
                    gutterBottom
                    align='left'
                  >
                    Essential
                  </Typography>
                  <Divider
                    variant='fullWidth'
                    style={{ width: '100%', marginBottom: '1em' }}
                  />
                  <br />
                  <br />
                  <Typography
                    variant='subtitle1'
                    component='h1'
                    gutterBottom
                    align='left'
                  >
                    {'\u2022'} Fundamentals
                  </Typography>
                  <Typography
                    variant='subtitle1'
                    component='h1'
                    gutterBottom
                    align='left'
                  >
                    {'\u2022'} 1GB Database Storage
                  </Typography>
                  <Typography
                    variant='subtitle1'
                    component='h1'
                    gutterBottom
                    align='left'
                  >
                    {'\u2022'} Supports
                  </Typography>
                  <br />
                  <br />
                  <Divider
                    variant='fullWidth'
                    style={{ width: '100%', marginBottom: '1em' }}
                  />
                  {priceType === priceTypeOptions[0] ? (
                    <Typography
                      variant='subtitle1'
                      component='h1'
                      gutterBottom
                      align='left'
                    >
                      HKD 3000 <span>Per month</span>
                    </Typography>
                  ) : (
                    <Typography
                      variant='subtitle1'
                      component='h1'
                      gutterBottom
                      align='left'
                    >
                      HKD 2550 <span>Per month </span> <span>(Save 15%)</span>
                    </Typography>
                  )}

                  <br />
                  <Button
                    variant='outlined'
                    color='primary'
                    sx={{ color: 'white', borderColor: 'white' }}
                    onClick={() => {
                      window.open('mailto:hello@whateverworks.cc', '_blank')
                    }}
                  >
                    Contact Us
                  </Button>
                </Box>
              </Grid>
              {/* Advanced Price Grid and Box */}
              <Grid item xs={12} sm={4} sx={{ color: 'darkgrey' }}>
                <Box
                  width={'100%'}
                  padding={'24px'}
                  boxShadow={15}
                  borderRadius={'50px'}
                  display={'flex'}
                  flexDirection={'column'}
                >
                  <Typography
                    variant='h6'
                    component='h1'
                    gutterBottom
                    align='left'
                  >
                    Advanced
                  </Typography>
                  <Divider
                    variant='fullWidth'
                    style={{ width: '100%', marginBottom: '1em' }}
                  />
                  <br />
                  <br />
                  <Typography
                    variant='subtitle1'
                    component='h1'
                    gutterBottom
                    align='left'
                  >
                    {'\u2022'} Advanced
                  </Typography>
                  <Typography
                    variant='subtitle1'
                    component='h1'
                    gutterBottom
                    align='left'
                  >
                    {'\u2022'} 2GB Database Storage
                  </Typography>
                  <Typography
                    variant='subtitle1'
                    component='h1'
                    gutterBottom
                    align='left'
                  >
                    {'\u2022'} Supports
                  </Typography>
                  <br />
                  <br />
                  <Divider
                    variant='fullWidth'
                    style={{ width: '100%', marginBottom: '1em' }}
                  />
                  {priceType === priceTypeOptions[0] ? (
                    <Typography
                      variant='subtitle1'
                      component='h1'
                      gutterBottom
                      align='left'
                    >
                      (Coming Soon)
                    </Typography>
                  ) : (
                    <Typography
                      variant='subtitle1'
                      component='h1'
                      gutterBottom
                      align='left'
                    >
                      (Coming Soon)
                    </Typography>
                  )}

                  <br />
                  <Button
                    variant='outlined'
                    color='primary'
                    sx={{ color: 'white', borderColor: 'white' }}
                    disabled={true}
                  >
                    Contact Us
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </FadeInWhenVisibleBox>
        <FadeInWhenVisibleBox
          width={'fill-available'}
          height={'fit-content'}
          display={'none'}
          flexDirection={'column'}
          justifyContent={'center'}
          alignItems={'center'}
          animationType={FadeInWhenVisibleBoxAnimationType.SlideRight}
          boxShadow={15}
          margin={'24px'}
          padding={'24px'}
        >
          <Typography variant='h4' component='h1' gutterBottom align='center'>
            Partners
          </Typography>
          <Divider
            variant='middle'
            style={{ width: '50%', marginBottom: '1em' }}
          />
        </FadeInWhenVisibleBox>
        <ProductCommonFooter isShow={true} />
      </Box>
    </>
  )
}

export default SelfHostSolutionMainPage
