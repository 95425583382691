import { orange } from '@mui/material/colors'
import { ThemeOptions, createTheme } from '@mui/material/styles'

const baseRem = 1.2

const globalMuiThemeConfig: ThemeOptions = {
  breakpoints: {
    values: {
      xs: 600,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
      mobile: 0,
      tablet: 640,
    },
  },
  palette: {
    primary: {
      main: orange[500],
    },
    secondary: {
      main: orange[200],
    },
  },
  typography: {
    fontFamily: ['"Roboto"', 'sans-serif'].join(','),
    h1: {
      fontSize: 2.5 * baseRem + 'rem',
      fontWeight: 700,
    },
    h2: {
      fontSize: 2 * baseRem + 'rem',
      fontWeight: 700,
    },
    h3: {
      fontSize: 1.75 * baseRem + 'rem',
      fontWeight: 700,
    },
    h4: {
      fontSize: 1.5 * baseRem + 'rem',
      fontWeight: 700,
    },
    h5: {
      fontSize: 1.25 * baseRem + 'rem',
      fontWeight: 700,
    },
    h6: {
      fontSize: 1 * baseRem + 'rem',
      fontWeight: 700,
    },
    subtitle1: {
      fontSize: 1 * baseRem + 'rem',
      fontWeight: 400,
    },
    subtitle2: {
      fontSize: 0.875 * baseRem + 'rem',
      fontWeight: 400,
    },
    body1: {
      fontSize: 1 * baseRem + 'rem',
      fontWeight: 400,
    },
    body2: {
      fontSize: 0.875 * baseRem + 'rem',
      fontWeight: 400,
    },
    button: {
      fontSize: 0.875 * baseRem + 'rem',
      fontWeight: 700,
    },
    caption: {
      fontSize: 0.75 * baseRem + 'rem',
      fontWeight: 400,
    },
    overline: {
      fontSize: 0.75 * baseRem + 'rem',
      fontWeight: 400,
    },
  },
}

// DO NOT CHANGE LINES AFTER THIS MARK
export const globalMuiTheme = createTheme(globalMuiThemeConfig)
