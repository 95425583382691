// CompanyInfoCard.tsx
import React from 'react'
import { Box, CardContent, CardProps, Typography } from '@mui/material'
import AnimatedFloatingCard from '../../component/card/AnimatedFloatingCard'

interface CompanyWhyUsProps extends CardProps {
  id?: string
}

const CompanyWhyUsCard: React.FC<CompanyWhyUsProps> = ({
  id,
}: CompanyWhyUsProps) => {
  return (
    <AnimatedFloatingCard elevation={4} id={id}>
      <CardContent>
        <Box mb={3}>
          <Typography
            variant='h4'
            component='h2'
            gutterBottom
            align='center'
            textTransform={'uppercase'}
          >
            Why Us
          </Typography>

          <Typography variant='body1' paragraph align='center'>
            {`We offer customized system that seamlessly combines user-friendliness with advanced functionality. Crafted by a team of forward-thinking developers, our platform is continuously evolving to stay ahead of market trends and cater to our clients' dynamic needs.`}
          </Typography>
          <Typography variant='body1' paragraph align='center'>
            {`Moreover, our strategic partnership with a seasoned industry leader, boasting over 30 years of experience, empowers us to deliver top-tier hardware and software solutions, backed by unparalleled support services. We are committed to providing our clients with the best possible experience, and we are confident that our platform will help you achieve your business goals.`}
          </Typography>
        </Box>
      </CardContent>
    </AnimatedFloatingCard>
  )
}

export default CompanyWhyUsCard
