import { animated, useSpring } from 'react-spring'
import { useInView } from 'react-intersection-observer'
import { Box, BoxProps } from '@mui/material'
import React, { ReactNode } from 'react'

// Animation
const AnimatedBox = animated(Box)

// Enum of animation types.
export enum FadeInWhenVisibleBoxAnimationType {
  SlideUp,
  SlideDown,
  SlideLeft,
  SlideRight,
  ZoomIn,
  ZoomOut,
}

interface FadeInWhenVisibleBoxProps extends BoxProps {
  children: ReactNode
  animationType?: FadeInWhenVisibleBoxAnimationType
}

// Local Variables
export const FadeInWhenVisibleBox: React.FC<FadeInWhenVisibleBoxProps> = ({
  children,
  animationType = FadeInWhenVisibleBoxAnimationType.SlideUp, // Default animation type is SlideUp
  ...props
}) => {
  const [ref, inView] = useInView({
    triggerOnce: true,
  })

  const animation = useSpring({
    opacity: inView ? 1 : 0,
    transform: getTransform(animationType),
  })

  function getTransform(type: FadeInWhenVisibleBoxAnimationType) {
    switch (type) {
      case FadeInWhenVisibleBoxAnimationType.SlideUp:
        return inView ? 'translateY(0)' : 'translateY(50px)'
      case FadeInWhenVisibleBoxAnimationType.SlideDown:
        return inView ? 'translateY(0)' : 'translateY(-50px)'
      case FadeInWhenVisibleBoxAnimationType.SlideLeft:
        return inView ? 'translateX(0)' : 'translateX(50px)'
      case FadeInWhenVisibleBoxAnimationType.SlideRight:
        return inView ? 'translateX(0)' : 'translateX(-50px)'
      case FadeInWhenVisibleBoxAnimationType.ZoomIn:
        return inView ? 'scale(1)' : 'scale(0.8)'
      case FadeInWhenVisibleBoxAnimationType.ZoomOut:
        return inView ? 'scale(1)' : 'scale(1.2)'
      default:
        return ''
    }
  }

  return (
    <AnimatedBox ref={ref} style={animation} {...props}>
      {children}
    </AnimatedBox>
  )
}
