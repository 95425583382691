// CompanyInfoCard.tsx
import React from 'react'
import {
  Box,
  CardContent,
  Typography,
  IconButton,
  CardProps,
} from '@mui/material'
import { Email } from '@mui/icons-material'
import AnimatedFloatingCard from '../../component/card/AnimatedFloatingCard'

interface CompanyContactProps extends CardProps {
  id?: string
}

const CompanyContactCard: React.FC<CompanyContactProps> = ({
  id,
}: CompanyContactProps) => {
  const handleClick = () => {
    window.location.href = 'mailto:hello@whateverworks.cc'
  }

  return (
    <AnimatedFloatingCard elevation={4} id={id}>
      <CardContent>
        <Box>
          <Typography
            variant='h4'
            component='h2'
            gutterBottom
            align='center'
            textTransform={'uppercase'}
          >
            Contact Us
          </Typography>
        </Box>
        <Box display='flex' justifyContent='center' alignItems='center' my={2}>
          <Typography variant='body1' component='body' mr={1} align='center'>
            {`Do you have a question or an idea you'd like to bring to life? We'd love to hear from you. As a software development company, we're always eager to take on new challenges and turn your concepts into reality. Please don't hesitate to reach out to us.`}
          </Typography>
        </Box>
        <Box display='flex' justifyContent='center' alignItems='center' my={2}>
          <IconButton
            onClick={handleClick}
            aria-label='send email to hello@whateverworks.cc'
            edge='end'
            color='primary'
          >
            <Email />
          </IconButton>
          <Typography variant='body1' component='span' ml={1}>
            <a
              href='mailto:hello@whateverworks.cc'
              aria-label='send email to hello@whateverworks.cc'
              style={{ textDecoration: 'none', color: 'inherit' }}
            >
              hello@whateverworks.cc
            </a>
          </Typography>
        </Box>
      </CardContent>
    </AnimatedFloatingCard>
  )
}

export default CompanyContactCard
