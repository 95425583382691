import React from 'react'
import {
  FadeInWhenVisibleBox,
  FadeInWhenVisibleBoxAnimationType,
} from '../../component/common/FadeInWhenVisibleBox'
import ProductCommonFooter from '../product/ProductCommonFooter'
import { Box, Button, Divider, Grid, Typography } from '@mui/material'
import ProductCommonHeader from '../product/ProductCommonHeader'

const PrivacyPolicyPage: React.FC = () => {
  return (
    <>
      <ProductCommonHeader />
      <Box
        id={'main-box'}
        color={'white'}
        sx={{
          width: '100%',
          height: '100%',
          minHeight: '100vh',
          background: 'black',
        }}
        display={'flex'}
        flexDirection={'column'}
        justifyContent={'flex-start'}
        alignItems={'flex-start'}
        gap={10}
      >
        <Box
          width={'100%'}
          height={'83px'}
          justifyContent={'center'}
          alignItems={'center'}
          maxWidth={'100%'}
        ></Box>

        <FadeInWhenVisibleBox
          width={'fill-available'}
          maxWidth={'100%'}
          height={'auto'}
          display={'flex'}
          flexDirection={'column'}
          justifyContent={'flex-start'}
          alignItems={'flex-start'}
          paddingTop={'2em'}
          paddingBottom={'2em'}
          paddingLeft={'2em'}
          paddingRight={'2em'}
        >
          <Typography variant='h4' component='h1' gutterBottom align='left'>
            Privacy Policy for Whatever Works Development Co., Ltd.
          </Typography>
          <Divider
            variant='middle'
            style={{ width: '50%', marginBottom: '1em' }}
          />
          <Typography
            variant='subtitle1'
            component='p'
            align='left'
            maxWidth={'80%'}
          >
            At Whatever Works, accessible from https://www.whateverworks.cc, one
            of our main priorities is the privacy of our visitors. This Privacy
            Policy document contains types of information that is collected and
            recorded by Whatever Works and how we use it.
          </Typography>
          <Typography
            variant='subtitle1'
            component='p'
            align='left'
            maxWidth={'80%'}
          >
            Whatever Works Development Limited is committed to providing you
            with the highest levels of customer service, including protecting
            your privacy.
          </Typography>
          <Typography
            variant='subtitle1'
            component='p'
            align='left'
            maxWidth={'80%'}
          >
            If you have additional questions or require more information about
            our Privacy Policy, do not hesitate to contact us.
          </Typography>
          <Typography
            variant='subtitle1'
            component='p'
            align='left'
            maxWidth={'80%'}
          >
            This Privacy Policy applies only to our online activities and is
            valid for visitors to our website with regards to the information
            that they shared and/or collect in Whatever Works. This policy is
            not applicable to any information collected offline or via channels
            other than this website.
          </Typography>
        </FadeInWhenVisibleBox>

        <FadeInWhenVisibleBox
          width={'fill-available'}
          maxWidth={'100%'}
          height={'auto'}
          display={'flex'}
          flexDirection={'column'}
          justifyContent={'flex-start'}
          alignItems={'flex-start'}
          paddingTop={'2em'}
          paddingBottom={'2em'}
          paddingLeft={'2em'}
          paddingRight={'2em'}
        >
          <Typography variant='h4' component='h1' gutterBottom align='left'>
            1. Introduction
          </Typography>
          <Divider
            variant='middle'
            style={{ width: '50%', marginBottom: '1em' }}
          />
          <Typography
            variant='subtitle1'
            component='p'
            align='left'
            maxWidth={'80%'}
          >
            We will endeavour to make sure our policies and practices in
            relation to the collection, use, retention, transfer and access of
            personal data (as defined in the Ordinance) supplied by you comply
            with the requirements of the Personal Data (Privacy) Ordinance (Cap.
            486, the laws of Hong Kong) (the “Ordinance”).
          </Typography>
        </FadeInWhenVisibleBox>

        <FadeInWhenVisibleBox
          width={'fill-available'}
          maxWidth={'100%'}
          height={'auto'}
          display={'flex'}
          flexDirection={'column'}
          justifyContent={'flex-start'}
          alignItems={'flex-start'}
          paddingTop={'2em'}
          paddingBottom={'2em'}
          paddingLeft={'2em'}
          paddingRight={'2em'}
        >
          <Typography variant='h4' component='h1' gutterBottom align='left'>
            2. Collection of Personal Information
          </Typography>
          <Divider
            variant='middle'
            style={{ width: '50%', marginBottom: '1em' }}
          />
          <Typography
            variant='subtitle1'
            component='p'
            align='left'
            maxWidth={'80%'}
          >
            Personal Information is data that can be used to identify
            individuals. Where you contact us through your access and use of
            website, web-based applications, or mobile applications, and provide
            us with information such as your name, company, industry, postal
            address, billing address, email address, payment information we may
            collect, use or store such Personal Information to better manage
            your relationship with us and serve you on any of the following and
            for other purposes that shall be agreed between you and us:
          </Typography>
          <Typography
            variant='subtitle1'
            component='p'
            align='left'
            maxWidth={'80%'}
            paddingLeft={2}
          >
            • Providing information as per your request Sending corporate or
            promotion materials
          </Typography>
          <Typography
            variant='subtitle1'
            component='p'
            align='left'
            maxWidth={'80%'}
            paddingLeft={2}
          >
            • Sending corporate or promotion materials (you can opt out anytime
            by updating us as to your preference)
          </Typography>
          <Typography
            variant='subtitle1'
            component='p'
            align='left'
            maxWidth={'80%'}
            paddingLeft={2}
          >
            • Enrolling you into upcoming events and/or activities organized
            and/or held by us
          </Typography>
          <Typography
            variant='subtitle1'
            component='p'
            align='left'
            maxWidth={'80%'}
            paddingLeft={2}
          >
            • Performing internal statistical and analysis exercise
          </Typography>
          <Typography
            variant='subtitle1'
            component='p'
            align='left'
            maxWidth={'80%'}
            paddingLeft={2}
          >
            • Processing and responding to any complaints made by you
          </Typography>
        </FadeInWhenVisibleBox>

        <FadeInWhenVisibleBox
          width={'fill-available'}
          maxWidth={'100%'}
          height={'auto'}
          display={'flex'}
          flexDirection={'column'}
          justifyContent={'flex-start'}
          alignItems={'flex-start'}
          paddingTop={'2em'}
          paddingBottom={'2em'}
          paddingLeft={'2em'}
          paddingRight={'2em'}
        >
          <Typography variant='h4' component='h1' gutterBottom align='left'>
            3. Disclosure of Personal Data
          </Typography>
          <Divider
            variant='middle'
            style={{ width: '50%', marginBottom: '1em' }}
          />
          <Typography
            variant='subtitle1'
            component='p'
            align='left'
            maxWidth={'80%'}
          >
            In order to deliver the services you requested, your Personal
            Information will be retained by us and will be accessible to our
            employees and (if required) to our strategic partners for any of the
            purposes stated in this Privacy Policy. You authorize us to disclose
            all or any of your Personal Information to such third parties.
          </Typography>
          <Typography
            variant='subtitle1'
            component='p'
            align='left'
            maxWidth={'80%'}
          >
            This Privacy Policy applies only to personal data collected via this
            website. We are not responsible for the privacy practices or
            policies of other websites accessible via this website. By
            activating a link (eg, by clicking on the banner of an advertiser),
            you leave this website and we do not have control over any personal
            data or any other information you give to any other person or entity
            after you have left this website.
          </Typography>
        </FadeInWhenVisibleBox>

        <FadeInWhenVisibleBox
          width={'fill-available'}
          maxWidth={'100%'}
          height={'auto'}
          display={'flex'}
          flexDirection={'column'}
          justifyContent={'flex-start'}
          alignItems={'flex-start'}
          paddingTop={'2em'}
          paddingBottom={'2em'}
          paddingLeft={'2em'}
          paddingRight={'2em'}
        >
          <Typography variant='h4' component='h1' gutterBottom align='left'>
            4. Purposes of Using Personal Data
          </Typography>
          <Divider
            variant='middle'
            style={{ width: '50%', marginBottom: '1em' }}
          />
          <Typography
            variant='subtitle1'
            component='p'
            align='left'
            maxWidth={'80%'}
          >
            Personal data supplied by you will be retained in one or more of our
            databases and in different formats and be secured with restricted
            access by our authorized personnel through appropriate security
            protocols for authentication and authorization (which may include
            the use of SSL, encryption, firewalls and data backup). Your
            personal data will be kept in the strictest confidence and for a
            reasonable period necessary to attain the above specified purposes.
          </Typography>
          <Typography
            variant='subtitle1'
            component='p'
            align='left'
            maxWidth={'80%'}
          >
            Personal data provided by you may be used for any of the following
            purposes and/or for other purposes as may be agreed between you and
            us and/or as required by law:
          </Typography>
          <Typography
            variant='subtitle1'
            component='p'
            align='left'
            maxWidth={'80%'}
            paddingLeft={2}
          >
            • Registering, operating or maintaining your account with us
          </Typography>
          <Typography
            variant='subtitle1'
            component='p'
            align='left'
            maxWidth={'80%'}
            paddingLeft={2}
          >
            • Purchasing goods and/or subscribing for services rendered by us
          </Typography>
          <Typography
            variant='subtitle1'
            component='p'
            align='left'
            maxWidth={'80%'}
            paddingLeft={2}
          >
            • Facilitating and following up your online transactions with us,
            including but not limited to processing of any payment instructions
          </Typography>
          <Typography
            variant='subtitle1'
            component='p'
            align='left'
            maxWidth={'80%'}
            paddingLeft={2}
          >
            • Marketing and telemarketing services of goods and/or services of
            our parent company and/or its respective subsidiaries and affiliated
            companies (please see further paragraph 6 hereinbelow)
          </Typography>
          <Typography
            variant='subtitle1'
            component='p'
            align='left'
            maxWidth={'80%'}
            paddingLeft={2}
          >
            • Enabling us to comply with our obligations to interconnect or
            comply with industry practices
          </Typography>
          <Typography
            variant='subtitle1'
            component='p'
            align='left'
            maxWidth={'80%'}
            paddingLeft={2}
          >
            • Market research for customer & website usage analysis
          </Typography>
          <Typography
            variant='subtitle1'
            component='p'
            align='left'
            maxWidth={'80%'}
            paddingLeft={2}
          >
            • Serving any purposes relating to any of the foregoing
          </Typography>
        </FadeInWhenVisibleBox>

        <FadeInWhenVisibleBox
          width={'fill-available'}
          maxWidth={'100%'}
          height={'auto'}
          display={'flex'}
          flexDirection={'column'}
          justifyContent={'flex-start'}
          alignItems={'flex-start'}
          paddingTop={'2em'}
          paddingBottom={'2em'}
          paddingLeft={'2em'}
          paddingRight={'2em'}
        >
          <Typography variant='h4' component='h1' gutterBottom align='left'>
            5. Use of Personal Data for Marketing Activities (if applicable)
          </Typography>
          <Divider
            variant='middle'
            style={{ width: '50%', marginBottom: '1em' }}
          />
          <Typography
            variant='subtitle1'
            component='p'
            align='left'
            maxWidth={'80%'}
          >
            Personal data supplied by you may be used for direct marketing
            activities (as defined in the Ordinance) only in accordance with the
            requirements set out under the Ordinance. In that event, you will
            be:
          </Typography>
          <Typography
            variant='subtitle1'
            component='p'
            align='left'
            maxWidth={'80%'}
            paddingLeft={2}
          >
            • Informed that your personal data will be used for direct marketing
            activities
          </Typography>
          <Typography
            variant='subtitle1'
            component='p'
            align='left'
            maxWidth={'80%'}
            paddingLeft={2}
          >
            • Informed of, with specific information, the kinds of personal data
            to be used and the classes of marketing subjects in relation to
            which the personal data is to be used
          </Typography>
          <Typography
            variant='subtitle1'
            component='p'
            align='left'
            maxWidth={'80%'}
            paddingLeft={2}
          >
            • Informed that your personal data will not be used unless we have
            received your written consent
          </Typography>
          <Typography
            variant='subtitle1'
            component='p'
            align='left'
            maxWidth={'80%'}
            paddingLeft={2}
          >
            • Provided, in case your consent to the use of personal data for
            direct marketing activities is given orally, a written confirmation
            within 14 days after receiving your oral consent
          </Typography>
          <Typography
            variant='subtitle1'
            component='p'
            align='left'
            maxWidth={'80%'}
            paddingLeft={2}
          >
            • Given opportunity to communicate your consent at no extra charge
            from us
          </Typography>
          <Typography
            variant='subtitle1'
            component='p'
            align='left'
            maxWidth={'80%'}
            paddingLeft={2}
          >
            • Notified the first time your personal data is used for direct
            marketing activities and that we will cease to use your personal
            data for direct marketing activities if you wish
          </Typography>
          <Typography
            variant='subtitle1'
            component='p'
            align='left'
            maxWidth={'80%'}
            paddingLeft={2}
          >
            • Given the liberty, at any time, to require cessation of the use of
            your personal data for direct marketing activities at no extra
            charge from us. Upon receiving your opt-out request, we will stop
            using your personal data for direct marketing activities as soon as
            possible but otherwise shall not be later than 10 working days from
            the date of receiving your opt-out request
          </Typography>
        </FadeInWhenVisibleBox>
        <FadeInWhenVisibleBox
          width={'fill-available'}
          maxWidth={'100%'}
          height={'auto'}
          display={'flex'}
          flexDirection={'column'}
          justifyContent={'flex-start'}
          alignItems={'flex-start'}
          paddingTop={'2em'}
          paddingBottom={'2em'}
          paddingLeft={'2em'}
          paddingRight={'2em'}
        >
          <Typography variant='h4' component='h1' gutterBottom align='left'>
            6. Personal Data to Third Parties
          </Typography>
          <Divider
            variant='middle'
            style={{ width: '50%', marginBottom: '1em' }}
          />
          <Typography
            variant='subtitle1'
            component='p'
            align='left'
            maxWidth={'80%'}
          >
            We will not release personal data supplied by you to third parties
            except in accordance with the Ordinance or in the event of such
            release is requested by:
          </Typography>
          <Typography
            variant='subtitle1'
            component='p'
            align='left'
            maxWidth={'80%'}
            paddingLeft={2}
          >
            • Governmental and/or regulatory and/or statutory authorities and/or
            court orders in compliance with applicable laws
          </Typography>
          <Typography
            variant='subtitle1'
            component='p'
            align='left'
            maxWidth={'80%'}
            paddingLeft={2}
          >
            • Third party service providers which provide supporting services to
            us; who (i) are under duty of confidentiality to us with respect to
            the use, hold, process, retain and/or transfer of personal data
            supplied by you; and (ii) have the need to know or handle such
            personal data (please see further paragraph 8 hereinbelow)
          </Typography>
        </FadeInWhenVisibleBox>

        <FadeInWhenVisibleBox
          width={'fill-available'}
          maxWidth={'100%'}
          height={'auto'}
          display={'flex'}
          flexDirection={'column'}
          justifyContent={'flex-start'}
          alignItems={'flex-start'}
          paddingTop={'2em'}
          paddingBottom={'2em'}
          paddingLeft={'2em'}
          paddingRight={'2em'}
        >
          <Typography variant='h4' component='h1' gutterBottom align='left'>
            7. Transference of Personal Data to Third Parties
          </Typography>
          <Divider
            variant='middle'
            style={{ width: '50%', marginBottom: '1em' }}
          />
          <Typography
            variant='subtitle1'
            component='p'
            align='left'
            maxWidth={'80%'}
          >
            Personal data provided by you may be provided to third parties for
            direct marketing activities (i.e. cross-marketing) only in
            accordance with the requirements set out under the Ordinance. In
            that event, you will be:
          </Typography>
          <Typography
            variant='subtitle1'
            component='p'
            align='left'
            maxWidth={'80%'}
            paddingLeft={2}
          >
            • Informed in writing of our intention to provide your personal data
            to third parties for direct marketing activities;
          </Typography>
          <Typography
            variant='subtitle1'
            component='p'
            align='left'
            maxWidth={'80%'}
            paddingLeft={2}
          >
            • Informed of, with specific information, the kinds of personal data
            to be provided, the classes of marketing subjects and the class of
            persons in relation to which the personal data is to be provided
          </Typography>
          <Typography
            variant='subtitle1'
            component='p'
            align='left'
            maxWidth={'80%'}
            paddingLeft={2}
          >
            • Informed in writing that provision of your personal data to third
            parties for direct marketing activities will be for gain (if that is
            the case);
          </Typography>
          <Typography
            variant='subtitle1'
            component='p'
            align='left'
            maxWidth={'80%'}
            paddingLeft={2}
          >
            • Informed that your personal data will not be provided to third
            parties for direct marketing activities unless we have received your
            written consent;
          </Typography>
          <Typography
            variant='subtitle1'
            component='p'
            align='left'
            maxWidth={'80%'}
            paddingLeft={2}
          >
            • Given opportunity to communicate your consent at no extra charge
            from us
          </Typography>
          <Typography
            variant='subtitle1'
            component='p'
            align='left'
            maxWidth={'80%'}
            paddingLeft={2}
          >
            • Given the liberty, at any time, to require the cessation of our
            provision of personal data to third parties for direct marketing
            activities and notify the third parties to cease the use of your
            personal data in direct marketing activities at no extra charge from
            us. Upon receiving such request, we will stop providing your
            personal data to any third party for direct marketing activities,
            and inform the third parties to whom the personal data has been so
            provided to stop using personal data supplied by you for the direct
            marketing activities, as soon as possible but in any event shall not
            be later than 10 working days from the date of receiving such
            request
          </Typography>
        </FadeInWhenVisibleBox>

        <FadeInWhenVisibleBox
          width={'fill-available'}
          maxWidth={'100%'}
          height={'auto'}
          display={'flex'}
          flexDirection={'column'}
          justifyContent={'flex-start'}
          alignItems={'flex-start'}
          paddingTop={'2em'}
          paddingBottom={'2em'}
          paddingLeft={'2em'}
          paddingRight={'2em'}
        >
          <Typography variant='h4' component='h1' gutterBottom align='left'>
            8. The Safety of Personal Data
          </Typography>
          <Divider
            variant='middle'
            style={{ width: '50%', marginBottom: '1em' }}
          />
          <Typography
            variant='subtitle1'
            component='p'
            align='left'
            maxWidth={'80%'}
          >
            Notwithstanding our security measures for protecting your personal
            data, you acknowledge that no data transmission over the Internet is
            completely secure, and by providing your personal data you are
            transmitting information at your own risk.
          </Typography>
        </FadeInWhenVisibleBox>

        <FadeInWhenVisibleBox
          width={'fill-available'}
          maxWidth={'100%'}
          height={'auto'}
          display={'flex'}
          flexDirection={'column'}
          justifyContent={'flex-start'}
          alignItems={'flex-start'}
          paddingTop={'2em'}
          paddingBottom={'2em'}
          paddingLeft={'2em'}
          paddingRight={'2em'}
        >
          <Typography variant='h4' component='h1' gutterBottom align='left'>
            9. Your Right Under the Ordinance
          </Typography>
          <Divider
            variant='middle'
            style={{ width: '50%', marginBottom: '1em' }}
          />
          <Typography
            variant='subtitle1'
            component='p'
            align='left'
            maxWidth={'80%'}
          >
            Under and in accordance with the Ordinance, among others, you have
            the rights:
          </Typography>
          <Typography
            variant='subtitle1'
            component='p'
            align='left'
            maxWidth={'80%'}
            paddingLeft={2}
          >
            • To check whether we are holding your personal data
          </Typography>
          <Typography
            variant='subtitle1'
            component='p'
            align='left'
            maxWidth={'80%'}
            paddingLeft={2}
          >
            • To access your personal data held by us; and/or
          </Typography>
          <Typography
            variant='subtitle1'
            component='p'
            align='left'
            maxWidth={'80%'}
            paddingLeft={2}
          >
            • To require us to correct any of your personal data which is or has
            become inaccurate.
          </Typography>
        </FadeInWhenVisibleBox>

        <FadeInWhenVisibleBox
          width={'fill-available'}
          maxWidth={'100%'}
          height={'auto'}
          display={'flex'}
          flexDirection={'column'}
          justifyContent={'flex-start'}
          alignItems={'flex-start'}
          paddingTop={'2em'}
          paddingBottom={'2em'}
          paddingLeft={'2em'}
          paddingRight={'2em'}
        >
          <Typography variant='h4' component='h1' gutterBottom align='left'>
            10. Website Cookies
          </Typography>
          <Divider
            variant='middle'
            style={{ width: '50%', marginBottom: '1em' }}
          />
          <Typography
            variant='subtitle1'
            component='p'
            align='left'
            maxWidth={'80%'}
          >
            Cookies are small bits of data automatically stored in the hard
            drive of the end user, which can save the user from registering
            again when re-visiting a web site and are commonly used to track
            preferences in relation to the subject of such website. If you
            enable these cookies, then your web browser adds the text in a small
            file. You may wish to set your web browser to notify you of a cookie
            placement request or refuse to accept cookies by modifying relevant
            internet options or browsing preferences of your computer system,
            but to do so you may not be able to utilize or activate certain
            available functions on this Website.
          </Typography>
        </FadeInWhenVisibleBox>

        <FadeInWhenVisibleBox
          width={'fill-available'}
          maxWidth={'100%'}
          height={'auto'}
          display={'flex'}
          flexDirection={'column'}
          justifyContent={'flex-start'}
          alignItems={'flex-start'}
          paddingTop={'2em'}
          paddingBottom={'2em'}
          paddingLeft={'2em'}
          paddingRight={'2em'}
        >
          <Typography variant='h4' component='h1' gutterBottom align='left'>
            11. Google Analytics
          </Typography>
          <Typography
            variant='subtitle1'
            component='p'
            align='left'
            maxWidth={'80%'}
          >
            This Website uses Google Analytics, a web analytics service provided
            by Google, Inc. (“Google”). Google uses cookies, which are text
            files placed on your computer, to collect and process data about how
            you use this Website. For more information regarding how Google uses
            data when you use this Website, please visit
            www.google.com/policies/privacy/partners/.
          </Typography>
        </FadeInWhenVisibleBox>

        <FadeInWhenVisibleBox
          width={'fill-available'}
          maxWidth={'100%'}
          height={'auto'}
          display={'flex'}
          flexDirection={'column'}
          justifyContent={'flex-start'}
          alignItems={'flex-start'}
          paddingTop={'2em'}
          paddingBottom={'2em'}
          paddingLeft={'2em'}
          paddingRight={'2em'}
        >
          <Typography variant='h4' component='h1' gutterBottom align='left'>
            12. Outside the Scope of Protection
          </Typography>
          <Typography
            variant='subtitle1'
            component='p'
            align='left'
            maxWidth={'80%'}
          >
            We may have third party merchants or service providers hosted in
            this Website which are operated by third party merchants or service
            providers. If you want to use, order or receive any services and/or
            products from any of them, you shall take the risk that personal
            data supplied by you, once transferred to any of such merchants or
            service providers, will be beyond our control and thus outside the
            scope of protection afforded by us.
          </Typography>
        </FadeInWhenVisibleBox>

        <FadeInWhenVisibleBox
          width={'fill-available'}
          maxWidth={'100%'}
          height={'auto'}
          display={'flex'}
          flexDirection={'column'}
          justifyContent={'flex-start'}
          alignItems={'flex-start'}
          paddingTop={'2em'}
          paddingBottom={'2em'}
          paddingLeft={'2em'}
          paddingRight={'2em'}
        >
          <Typography variant='h4' component='h1' gutterBottom align='left'>
            13. Contact Us
          </Typography>
          <Typography
            variant='subtitle1'
            component='p'
            align='left'
            maxWidth={'80%'}
          >
            If you have any requests for access to your personal data or for the
            correction of your personal data, or if you would like any further
            information regarding our policies and practices, please address
            your enquiries to:
          </Typography>
          <Typography
            variant='subtitle1'
            component='p'
            align='left'
            maxWidth={'80%'}
            paddingLeft={2}
          >
            • Email: hello@whateverworks.cc
          </Typography>
        </FadeInWhenVisibleBox>

        <FadeInWhenVisibleBox
          width={'fill-available'}
          maxWidth={'100%'}
          height={'auto'}
          display={'flex'}
          flexDirection={'column'}
          justifyContent={'flex-start'}
          alignItems={'flex-start'}
          paddingTop={'2em'}
          paddingBottom={'2em'}
          paddingLeft={'2em'}
          paddingRight={'2em'}
        >
          <Typography
            variant='h4'
            component='h1'
            gutterBottom
            align='left'
          ></Typography>
          <Divider
            variant='middle'
            style={{ width: '50%', marginBottom: '1em' }}
          />
          <Typography
            variant='subtitle1'
            component='p'
            align='left'
            maxWidth={'80%'}
          >
            This Privacy Statement may be amended from time to time and all
            handling of personal data will be governed by the most recent
            version of this Privacy Policy, available on our website at
            https://www.whateverworks.cc.
          </Typography>
          <br />
          <Typography
            variant='subtitle1'
            component='p'
            align='left'
            maxWidth={'80%'}
          >
            Date of update: 14 Aug 2023
          </Typography>
        </FadeInWhenVisibleBox>

        <ProductCommonFooter isShow={true} />
      </Box>
    </>
  )
}

export default PrivacyPolicyPage
