// SectionButton.tsx
import React from 'react'
import { Link } from 'react-router-dom'
import { Button, MenuItem, Menu } from '@mui/material'

interface SectionButtonProps {
  title: string
  type?: 'Link' | 'Dropdown'
  description?: string
  disabled?: boolean
  onClickOverride?: () => void
  link?: string
  color?: string
  backgroundColor?: string
  children?: React.ReactNode
}

const SectionButton: React.FC<SectionButtonProps> = ({
  title,
  type = 'Link',
  backgroundColor = 'transparent',
  description = '',
  disabled = false,
  onClickOverride = null,
  link = null,
  color = 'primary',

  children,
}) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (onClickOverride) {
      onClickOverride()
    } else {
      setAnchorEl(event.currentTarget)
    }
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  if (type === 'Dropdown') {
    return (
      <>
        <Button
          onClick={handleClick}
          disabled={disabled}
          style={{ color: color }}
        >
          {title}
        </Button>
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
          slotProps={{
            paper: {
              style: {
                backgroundColor: backgroundColor,
                background: backgroundColor,
              },
            },
          }}
        >
          {children}
        </Menu>
      </>
    )
  }

  return (
    <Link to={link || '#'}>
      <Button
        onClick={handleClick}
        disabled={disabled}
        style={{ color: color }}
      >
        {title}
      </Button>
    </Link>
  )
}

export default SectionButton
