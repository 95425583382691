import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react'
import { Box, Button, ButtonGroup, useTheme } from '@mui/material'
import { useSpring, animated } from 'react-spring'

interface PriceTypeToggleProps {
  id?: string
  options: string[] // Must be length 2
  onChange?: (type: string) => void
}

export interface PriceTypeToggleRef {
  value: string
}

const PriceTypeToggle = forwardRef<PriceTypeToggleRef, PriceTypeToggleProps>(
  (props, ref) => {
    // Props validation
    if (props.options.length !== 2) {
      throw new Error('The options prop must have exactly 2 elements')
    }

    const activeRef = useRef(props.options[0])
    const [active, setActive] = useState(props.options[0])
    const [springProps, set] = useSpring(() => ({ left: '0px' }))

    // Update activeRef whenever active changes
    useEffect(() => {
      activeRef.current = active
    }, [active])

    const handleToggle = (type: string) => {
      setActive(type)
      set({ left: type === props.options[1] ? '100px' : '0px' })
      props.onChange && props.onChange(type)
    }

    const theme = useTheme()

    // Ref to expose active value
    useImperativeHandle(ref, () => {
      return { value: activeRef.current }
    })

    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          p: 1,
          position: 'relative',
        }}
      >
        <animated.div
          style={{
            position: 'absolute',
            height: '100%',
            width: '50%',
            backgroundColor: theme.palette.primary.main,
            borderRadius: '50px',
            ...springProps,
          }}
        />
        <ButtonGroup
          variant='outlined'
          color='primary'
          aria-label='contained primary button group'
        >
          <Button
            onClick={() => handleToggle(props.options[0])}
            color={active === props.options[0] ? 'inherit' : 'secondary'}
            sx={{ borderRadius: '50px', zIndex: 1 }}
          >
            {props.options[0].charAt(0).toUpperCase() +
              props.options[0].slice(1)}
          </Button>
          <Button
            onClick={() => handleToggle(props.options[1])}
            color={active === props.options[1] ? 'inherit' : 'secondary'}
            sx={{ borderRadius: '50px', zIndex: 1 }}
          >
            {props.options[1].charAt(0).toUpperCase() +
              props.options[1].slice(1)}
          </Button>
        </ButtonGroup>
      </Box>
    )
  },
)

PriceTypeToggle.displayName = 'PriceTypeToggle'

export default PriceTypeToggle
