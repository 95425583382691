import i18next from 'i18next'

import { initReactI18next } from 'react-i18next'

import en from './en/general.json'
import sc from './sc/general.json'
import tc from './tc/general.json'

export const defaultNS = 'ns1'

export const resources = {
  en: { ns1: en },
  tc: { ns1: tc },
  sc: { ns1: sc },
}

i18next.use(initReactI18next).init({
  lng: 'en', // if you're using a language detector, do not define the lng option
  debug: true,
  resources,
  defaultNS,
})
