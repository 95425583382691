// CompanyHeader.tsx
import React from 'react'
import { AppBar, Toolbar, Typography } from '@mui/material'
import { styled } from '@mui/system'
import { Link } from 'react-router-dom'

const Logo = styled('img')({
  height: '80px',
  width: 'auto',
  paddingRight: '16px',
})

const ProductCommonHeader: React.FC = () => {
  return (
    <AppBar
      position='absolute'
      sx={{ background: 'transparent', backdropFilter: 'blur(10px)' }}
    >
      <Toolbar>
        <Link to='/'>
          <Logo
            src='/images/logo/white-font-logo.png'
            alt='Company Name'
            height={'800px'}
          />
          {/* <Logo src='/images/logo/white-outline-logo-zoom.png' alt='Company Name' height={'80px'} /> */}
          {/* <Logo src='/images/logo/logo_b_n.png' alt='Company Name' height={'80px'} /> */}
        </Link>
        <Typography
          variant='h6'
          component='div'
          sx={{ flexGrow: 1 }}
        ></Typography>
      </Toolbar>
    </AppBar>
  )
}

export default ProductCommonHeader
