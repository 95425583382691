// mt-wms-frontend/src/App.tsx
import './App.css'
import { configuration } from './configuration/config/configLoader'
import { globalMuiTheme } from './configuration/theme/muiGlobalTheme'
import { uiGlobalTheme } from './configuration/theme/uiGlobalTheme'
import './i18n/config'
import logo from './logo.svg'
import ConstructingPage from './page/common/ConstructingPage'
import NotFoundPage from './page/common/NotFoundPage'
import MainPage from './page/main/MainPage'
import PrivacyPolicyPage from './page/misc/PrivacyPolicyPage'
import TermsOfServicePage from './page/misc/TermsOfServicePage'
import AMSMainPage from './page/product/ams/AMSMainPage'
import CustomizeSolutionMainPage from './page/product/customize-solution/CustomizeSolutionMainPage'
import SelfHostSolutionMainPage from './page/product/self-host-solution/SelfHostSolutionMainPage'
import { RootState } from './store/store'
import { Box, Container, ThemeProvider } from '@mui/material'
import jwt_decode from 'jwt-decode'
import React from 'react'
import { useSelector } from 'react-redux'
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
  Outlet,
} from 'react-router-dom'

function App() {
  // CONFIGURATION DEMO FOR REFERENCE
  console.debug(configuration)

  return (
    <ThemeProvider theme={globalMuiTheme}>
      <Box
        sx={{
          padding: 'none',
        }}
      >
        <Router basename='/'>
          <Routes>
            <Route
              path='/logout'
              element={
                <Navigate
                  to='/login'
                  replace
                  state={{ from: window.location.pathname }}
                />
              }
            />
            {/* <Route path='/ams' element={<Navigate to='/constructing' />} /> */}
            <Route path='/ams' element={<AMSMainPage />} />
            <Route
              path='/customize-solution'
              element={<CustomizeSolutionMainPage />}
            />
            <Route
              path='/self-host-solution'
              element={<SelfHostSolutionMainPage />}
            />

            {/* Misc routes */}
            <Route path='/pp' element={<PrivacyPolicyPage />} />
            <Route path='/tos' element={<TermsOfServicePage />} />

            {/* Other default routes */}
            <Route path='/constructing' element={<ConstructingPage />} />
            <Route path='/oops' element={<NotFoundPage />} />
            <Route path='/404' element={<Navigate to='/oops' />} />
            <Route path='/' element={<MainPage />} />
            <Route path='/*' element={<Navigate to='/oops' />} />
          </Routes>
        </Router>
      </Box>
    </ThemeProvider>
  )
}

export default App
