// CompanyInfoCard.tsx
import React from 'react'
import { Box, CardContent, CardProps, Typography } from '@mui/material'
import AnimatedFloatingCard from '../../component/card/AnimatedFloatingCard'

interface CompanySloganProps extends CardProps {
  id?: string
}

const CompanySloganCard: React.FC<CompanySloganProps> = ({
  id,
}: CompanySloganProps) => {
  return (
    <AnimatedFloatingCard elevation={4} id={id}>
      <CardContent>
        <Box>
          <Typography
            variant='h4'
            component='h2'
            gutterBottom
            align='center'
            textTransform={'uppercase'}
          >
            Our Motto
          </Typography>
          <Typography variant='body1' align='center'>
            Whatever Works: Crafting Solutions that Drive Success
          </Typography>
        </Box>
      </CardContent>
    </AnimatedFloatingCard>
  )
}

export default CompanySloganCard
