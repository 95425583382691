import React, { useEffect, useState } from 'react'
import { useSpring, animated, useSprings, config } from 'react-spring'
import { Box, Typography, Container } from '@mui/material'
import ProductCommonHeader from '../product/ProductCommonHeader'
import ProductCommonFooter from '../product/ProductCommonFooter'

const NotFoundPage: React.FC = () => {
  // Animation
  const props = useSpring({
    opacity: 1,
    marginLeft: 0,
    from: { opacity: 0, marginLeft: -500 },
    config: { duration: 1000 },
  })

  // Initialize circle states
  const [circles, setCircles] = useState([
    {
      x: Math.random() * window.innerWidth,
      y: Math.random() * window.innerHeight,
      radius: Math.random() * 100 + 100,
      opacity: Math.random() * 0.4 + 0.6,
    },
    {
      x: Math.random() * window.innerWidth,
      y: Math.random() * window.innerHeight,
      radius: Math.random() * 100 + 100,
      opacity: Math.random() * 0.4 + 0.6,
    },
  ])

  // Create springs for both circles
  const springs = useSprings(
    circles.length,
    circles.map((item) => ({
      x: item.x,
      y: item.y,
      radius: item.radius,
      opacity: item.opacity,
      config: config.slow,
    })),
  )

  // Update circle states at random intervals
  useEffect(() => {
    const interval = setInterval(() => {
      setCircles([
        {
          x: Math.random() * window.innerWidth,
          y: Math.random() * window.innerHeight,
          radius: Math.random() * 100 + 100,
          opacity: Math.random() * 0.4 + 0.4,
        },
        {
          x: Math.random() * window.innerWidth,
          y: Math.random() * window.innerHeight,
          radius: Math.random() * 100 + 100,
          opacity: Math.random() * 0.4 + 0.4,
        },
      ])
    }, 2000)
    return () => clearInterval(interval)
  }, [])

  // useEffect(() => {
  //   console.log('updated circle states:', circles);
  // }, [circles]);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        width: '100vw',
      }}
      bgcolor={'black'}
    >
      {springs.map((props, index) => (
        <animated.div
          key={index}
          style={{
            position: 'fixed',
            borderRadius: '50%',
            backgroundColor: 'white',
            width: props.radius.to((r) => r),
            height: props.radius.to((r) => r),
            left: props.x.to((x) => x),
            top: props.y.to((y) => y),
            opacity: props.opacity.to((o) => o),
            transform: props.x.to(
              (x) => `translate3d(${x}px, ${props.y.to((y) => y)}px, 0)`,
            ),
            zIndex: 100,
          }}
        />
      ))}
      <Box>
        <ProductCommonHeader />
      </Box>

      <Box
        sx={{
          flexGrow: 1,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Box
          sx={{
            background: 'transparent',
            backdropFilter: 'blur(10px)',
            color: 'grey',
            boxShadow: 'rgba(255, 255, 255, 0.3) 0px 25px 50px -12px',
            padding: 4,
          }}
        >
          <animated.div style={props}>
            <Box
              display='flex'
              justifyContent='center'
              alignItems='center'
              width='100%'
            >
              <img
                src='./images/common/404-error-page-ghost.png'
                alt='404 Error Page Ghost'
                height={200}
                width='auto'
              />
            </Box>

            <Typography variant='h4' mb={2} align='center'>
              {`Oops, you've found our junior developer's homepage!`}
            </Typography>
            <Typography variant='body1' align='center'>
              {`Despite sleeping on the couch most of the day, our junior developer still finds time
              to miss a few spots here and there.`}
            </Typography>
            <Typography variant='body1' align='center'>
              {`But don't worry, we've fired him over to the new page you were looking for.`}
            </Typography>
          </animated.div>
        </Box>
      </Box>
      <Box bgcolor={'inherit'}>
        <ProductCommonFooter isShow={true} />
      </Box>
    </Box>
  )
}

export default NotFoundPage
