import { Box, Typography } from '@mui/material'
import React from 'react'

import ProductCommonFooter from '../product/ProductCommonFooter'
import ProductCommonHeader from '../product/ProductCommonHeader'

const ConstructingPage: React.FC = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        width: '100vw',
        backgroundColor: 'rgba(0, 0, 0, 1)',
      }}
    >
      <Box>
        <ProductCommonHeader />
      </Box>

      <Box
        sx={{
          flexGrow: 1,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Box
          sx={{
            background: 'transparent',
            backdropFilter: 'blur(10px)',
            color: 'grey',
            boxShadow: 'rgba(255, 255, 255, 0.3) 0px 25px 50px -12px',
            padding: 4,
          }}
        >
          <Box
            display='flex'
            justifyContent='center'
            alignItems='center'
            width='100%'
          >
            <img
              src='./images/common/constructing.png'
              alt='constructing'
              width={250}
            />
          </Box>
          <br />
          <Typography variant='h4' mb={2} align='center'>
            {`Bear with us, we're building something amazing!`}
          </Typography>
          <Typography variant='body1' align='center'>
            {`Our digital construction team is hard at work, laying the bricks of the next web
            wonder.`}
          </Typography>
          <Typography variant='body1' align='center'>
            {`They've promised to keep the noise down and clean up after themselves.`}
          </Typography>
          <Typography variant='body1' align='center'>
            {`We can't wait for you to see what they're creating. Check back soon!`}
          </Typography>
        </Box>
      </Box>
      <Box>
        <ProductCommonFooter isShow={true} />
      </Box>
    </Box>
  )
}

export default ConstructingPage
