import { Box, BoxProps, Button, Divider, Grid, Typography } from '@mui/material'
import React, { ReactNode } from 'react'
import ProductCommonHeader from '../ProductCommonHeader'
import ProductCommonFooter from '../ProductCommonFooter'
import {
  FadeInWhenVisibleBox,
  FadeInWhenVisibleBoxAnimationType,
} from '../../../component/common/FadeInWhenVisibleBox'
import PriceTypeToggle, {
  PriceTypeToggleRef,
} from '../../../component/toggle/PriceTypeToggle'
import { useSpring, animated } from 'react-spring'
import Snowfall from 'react-snowfall'

const CustomizeSolutionMainPage: React.FC = () => {
  // Local variables
  const priceTypeOptions = ['Monthly', 'Yearly']

  // Local ref
  const priceTypeToggleRef = React.useRef<PriceTypeToggleRef>(null)

  // Local state
  const [priceType, setPriceType] = React.useState<string>(priceTypeOptions[0])
  const [yourContributionShowOptional, setYourContributionShowOptional] =
    React.useState<boolean>(false)

  // Local style
  const animationProps = useSpring({
    opacity: yourContributionShowOptional ? 1 : 0,
    transform: yourContributionShowOptional
      ? 'translateY(0)'
      : 'translateY(-20px)',
    config: { tension: 220, friction: 120 },
  })

  return (
    <>
      <Snowfall />
      <ProductCommonHeader />
      <Box
        id={'main-box'}
        color={'white'}
        sx={{
          width: '100%',
          height: '100%',
          minHeight: '100vh',
          background: 'transparent',
          backgroundImage: 'url(./images/common/background-02.png)',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          maxWidth: '100%',
          backgroundAttachment: 'fixed',
          overflowX: 'hidden',
        }}
        display={'flex'}
        flexDirection={'column'}
        justifyContent={'flex-start'}
        alignItems={'flex-start'}
        gap={10}
      >
        <Box
          width={'100%'}
          height={'83px'}
          justifyContent={'center'}
          alignItems={'center'}
          maxWidth={'100%'}
        ></Box>
        <FadeInWhenVisibleBox
          width={'100%'}
          maxWidth={'100%'}
          display={'flex'}
          flexDirection={'column'}
          justifyContent={'center'}
          alignItems={'center'}
        >
          <Box
            width={'100%'}
            maxWidth={'80%'}
            height={'auto'}
            display={'flex'}
            flexDirection={'column'}
            justifyContent={'center'}
            alignItems={'center'}
            paddingTop={'2em'}
            paddingBottom={'2em'}
          >
            <Typography variant='h3' component='h1' gutterBottom align='center'>
              Customized Solution for Your Business
            </Typography>
            <Divider
              variant='middle'
              style={{ width: '50%', marginBottom: '1em' }}
            />

            <Box
              width={'100%'}
              maxWidth={'100%'}
              height={'auto'}
              padding={'24px'}
            >
              <Grid container spacing={2}>
                <Grid item xs={12} sm={4}>
                  <FadeInWhenVisibleBox>
                    <Typography
                      variant='h6'
                      component='p'
                      align='left'
                      style={{
                        maxWidth: '80%',
                        margin: 'auto',
                        fontWeight: 'bold',
                      }}
                    >
                      {`01  Today's`}
                    </Typography>
                    <Typography
                      variant='subtitle1'
                      component='p'
                      align='left'
                      style={{
                        maxWidth: '80%',
                        margin: 'auto',
                        lineHeight: '1.2',
                      }}
                    >
                      {`In today's rapidly evolving digital landscape, a one-size-fits-all approach is no longer
                      enough. Your business is unique, and you need a software solution that reflects that.
                      That's where we come in.`}
                    </Typography>
                  </FadeInWhenVisibleBox>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <FadeInWhenVisibleBox>
                    <Typography
                      variant='h6'
                      component='p'
                      align='left'
                      style={{
                        maxWidth: '80%',
                        margin: 'auto',
                        fontWeight: 'bold',
                      }}
                    >
                      {`02  We can help`}
                    </Typography>
                    <Typography
                      variant='subtitle1'
                      component='p'
                      align='left'
                      style={{
                        maxWidth: '80%',
                        margin: 'auto',
                        lineHeight: '1.2',
                      }}
                    >
                      {`We specialize in developing tailor-made software solutions that perfectly match your
                      business needs. Our team of experienced developers and project managers work closely
                      with you, taking the time to understand your business, its goals, and its challenges.`}
                    </Typography>
                  </FadeInWhenVisibleBox>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <FadeInWhenVisibleBox>
                    <Typography
                      variant='h6'
                      component='p'
                      align='left'
                      style={{
                        maxWidth: '80%',
                        margin: 'auto',
                        fontWeight: 'bold',
                      }}
                    >
                      {`03  We build`}
                    </Typography>
                    <Typography
                      variant='subtitle1'
                      component='p'
                      align='left'
                      style={{
                        maxWidth: '80%',
                        margin: 'auto',
                        lineHeight: '1.2',
                      }}
                    >
                      {`Whether you need a robust CRM system, a scalable e-commerce platform, an efficient
                    inventory management system, or a cutting-edge web app, we've got you covered. Our customized software solutions are designed to streamline your operations, increase
                    productivity, and drive growth.`}
                    </Typography>
                  </FadeInWhenVisibleBox>
                </Grid>
              </Grid>
            </Box>
            <Box
              width={'100%'}
              maxWidth={'100%'}
              height={'auto'}
              padding={'24px'}
            >
              <Grid
                container
                spacing={2}
                alignItems={'center'}
                justifyContent={'center'}
              >
                <Grid item xs={12} sm={4}>
                  <FadeInWhenVisibleBox>
                    <Typography
                      variant='h6'
                      component='p'
                      align='left'
                      style={{
                        maxWidth: '80%',
                        margin: 'auto',
                        fontWeight: 'bold',
                      }}
                    >
                      {`04  We create`}
                    </Typography>
                    <Typography
                      variant='subtitle1'
                      component='p'
                      align='left'
                      style={{
                        maxWidth: '80%',
                        margin: 'auto',
                        lineHeight: '1.2',
                      }}
                    >
                      {`We use the latest technologies and follow best industry practices to ensure that your
                      software is reliable, secure, and easy to use. Plus, our solutions are flexible and
                      scalable, designed to grow with your business.`}
                    </Typography>
                  </FadeInWhenVisibleBox>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <FadeInWhenVisibleBox>
                    <Typography
                      variant='h6'
                      component='p'
                      align='left'
                      style={{
                        maxWidth: '80%',
                        margin: 'auto',
                        fontWeight: 'bold',
                      }}
                    >
                      {`05  We deliver`}
                    </Typography>
                    <Typography
                      variant='subtitle1'
                      component='p'
                      align='left'
                      style={{
                        maxWidth: '80%',
                        margin: 'auto',
                        lineHeight: '1.2',
                      }}
                    >
                      {`Don't settle for off-the-shelf software that doesn't quite fit your needs. Choose a
                      customized solution that's built for your business. Get in touch with us today to find
                      out how we can help transform your business with our tailor-made software solutions.`}
                    </Typography>
                  </FadeInWhenVisibleBox>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </FadeInWhenVisibleBox>

        <FadeInWhenVisibleBox
          width={'fill-available'}
          height={'fit-content'}
          display={'flex'}
          flexDirection={'row'}
          justifyContent={'center'}
          alignItems={'center'}
          gap={'24px'}
          animationType={FadeInWhenVisibleBoxAnimationType.ZoomIn}
          boxShadow={15}
          margin={'24px'}
          padding={'24px'}
          paddingY={'48px'}
        >
          <FadeInWhenVisibleBox minWidth={'50%'}>
            <Typography
              variant='h1'
              component='h1'
              gutterBottom
              align='center'
              fontWeight={'bolder'}
            >
              Why us?
            </Typography>
          </FadeInWhenVisibleBox>
          <FadeInWhenVisibleBox>
            <Typography
              variant='subtitle1'
              component='p'
              align='left'
              maxWidth={'80%'}
              lineHeight={1.2}
            >
              {`We understand that every business is unique, especially if you're a startup or an SME.
            That's why we've pioneered an innovative pricing model that puts your needs first.`}
            </Typography>
            <br />
            <Typography
              variant='subtitle1'
              component='p'
              align='left'
              maxWidth={'80%'}
              lineHeight={1.2}
            >
              {`Unlike traditional software development companies, we don't charge you for the
            development of your software solution. Instead, our pricing is based solely on your
            usage. This means you only pay for what you need, when you need it.`}
            </Typography>
            <br />
            <Typography
              variant='subtitle1'
              component='p'
              align='left'
              maxWidth={'80%'}
              lineHeight={1.2}
            >
              {`With us, you can focus on what you do best: running your business. We handle all the
            technical aspects for you, from maintaining and updating the software, to ensuring its
            security and scalability. We've got you covered, so you can rest easy knowing your
            software is in expert hands.`}
            </Typography>
          </FadeInWhenVisibleBox>
        </FadeInWhenVisibleBox>

        <FadeInWhenVisibleBox
          width={'fill-available'}
          height={'fit-content'}
          display={'flex'}
          flexDirection={'column'}
          justifyContent={'center'}
          alignItems={'center'}
          animationType={FadeInWhenVisibleBoxAnimationType.ZoomIn}
          boxShadow={15}
          margin={'24px'}
          padding={'24px'}
        >
          <Typography variant='h4' component='h1' gutterBottom align='center'>
            How does it work?
          </Typography>
          <Divider
            variant='middle'
            style={{ width: '50%', marginBottom: '1em' }}
          />

          <Box
            width={'80%'}
            display={'flex'}
            flexDirection={'row'}
            flexWrap='wrap'
            gap={3}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} sm={4}>
                <Box width={'100%'}>
                  <Typography
                    variant='h6'
                    component='h1'
                    gutterBottom
                    align='left'
                  >
                    Your Contribution
                  </Typography>
                  <Divider
                    variant='fullWidth'
                    style={{ width: '100%', marginBottom: '1em' }}
                  />
                  <Typography
                    variant='subtitle1'
                    component='h1'
                    gutterBottom
                    align='left'
                  >
                    {'\u2022'} Business requirements
                  </Typography>
                  {yourContributionShowOptional ? (
                    <animated.div style={animationProps}>
                      <Typography
                        variant='subtitle1'
                        component='h1'
                        gutterBottom
                        align='left'
                      >
                        {'\u2022'} Design mockups (optional)
                      </Typography>
                      <Typography
                        variant='subtitle1'
                        component='h1'
                        gutterBottom
                        align='left'
                      >
                        {'\u2022'} User stories (optional)
                      </Typography>
                      <Typography
                        variant='subtitle1'
                        component='h1'
                        gutterBottom
                        align='left'
                      >
                        {'\u2022'} API documentation if integration needed
                        (optional)
                      </Typography>
                      <Typography
                        variant='subtitle1'
                        component='h1'
                        gutterBottom
                        align='left'
                      >
                        {'\u2022'} Database documentation if integration needed
                        (optional)
                      </Typography>
                      <Typography
                        variant='subtitle2'
                        component='h1'
                        gutterBottom
                        align='left'
                        color={'darkgrey'}
                        onClick={() => setYourContributionShowOptional(false)}
                      >
                        {'-'} (hide optional)
                      </Typography>
                    </animated.div>
                  ) : (
                    <Typography
                      variant='subtitle2'
                      component='h1'
                      gutterBottom
                      align='left'
                      color={'darkgrey'}
                      onClick={() => setYourContributionShowOptional(true)}
                    >
                      {'+'} (show optional)
                    </Typography>
                  )}

                  <br />
                  <Typography
                    variant='subtitle1'
                    component='h1'
                    gutterBottom
                    align='left'
                    fontWeight={'bold'}
                  >
                    {'\u2022'} Monthly usage fee
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Box width={'100%'} color={'white'}>
                  <Typography
                    variant='h6'
                    component='h1'
                    gutterBottom
                    align='left'
                    fontWeight={'bold'}
                  >
                    We Deliver
                  </Typography>
                  <Divider
                    variant='fullWidth'
                    style={{ width: '100%', marginBottom: '1em' }}
                  />
                  <Typography
                    variant='subtitle1'
                    component='h1'
                    gutterBottom
                    align='left'
                    fontWeight={'bold'}
                  >
                    {'\u2022'} Software solution
                  </Typography>
                  <Typography
                    variant='subtitle1'
                    component='h1'
                    gutterBottom
                    align='left'
                    fontWeight={'bold'}
                  >
                    {'\u2022'} Architecture design
                  </Typography>
                  <Typography
                    variant='subtitle1'
                    component='h1'
                    gutterBottom
                    align='left'
                    fontWeight={'bold'}
                  >
                    {'\u2022'} Cloud-based service (SaaS)
                  </Typography>
                  <Typography
                    variant='subtitle1'
                    component='h1'
                    gutterBottom
                    align='left'
                    fontWeight={'bold'}
                  >
                    {'\u2022'} Technical consultation
                  </Typography>
                  <Typography
                    variant='subtitle1'
                    component='h1'
                    gutterBottom
                    align='left'
                    fontWeight={'bold'}
                  >
                    {'\u2022'} API Integration (optional)
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Box width={'100%'} color={'white'}>
                  <Typography
                    variant='h6'
                    component='h1'
                    gutterBottom
                    align='left'
                    fontWeight={'bold'}
                  >
                    Our Extra Commitment
                  </Typography>
                  <Divider
                    variant='fullWidth'
                    style={{ width: '100%', marginBottom: '1em' }}
                  />
                  <Typography
                    variant='subtitle1'
                    component='h1'
                    gutterBottom
                    align='left'
                    fontWeight={'bold'}
                  >
                    {'\u2022'} Proof of concept demo
                  </Typography>
                  <Typography
                    variant='subtitle1'
                    component='h1'
                    gutterBottom
                    align='left'
                    fontWeight={'bold'}
                  >
                    {'\u2022'} Software development
                  </Typography>
                  <Typography
                    variant='subtitle1'
                    component='h1'
                    gutterBottom
                    align='left'
                    fontWeight={'bold'}
                  >
                    {'\u2022'} Software / Infrastructure maintenance
                  </Typography>
                  <Typography
                    variant='subtitle1'
                    component='h1'
                    gutterBottom
                    align='left'
                    fontWeight={'bold'}
                  >
                    {'\u2022'} Software / Infrastructure updates
                  </Typography>
                  <Typography
                    variant='subtitle1'
                    component='h1'
                    gutterBottom
                    align='left'
                    fontWeight={'bold'}
                  >
                    {'\u2022'} Technical support
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Box>
          <br />
          <br />
          <Box
            width={'80%'}
            display={'flex'}
            flexDirection={'row'}
            flexWrap='wrap'
            gap={3}
          >
            <Box
              width={'100%'}
              display={'flex'}
              flexDirection={'column'}
              alignItems={'center'}
            >
              <Button
                variant='outlined'
                color='inherit'
                onClick={() => {
                  window.location.href = 'mailto:hello@whateverworks.cc'
                }}
              >
                Contact Us
              </Button>
            </Box>
          </Box>
        </FadeInWhenVisibleBox>

        <FadeInWhenVisibleBox
          width={'fill-available'}
          height={'fit-content'}
          display={'none'}
          flexDirection={'column'}
          justifyContent={'center'}
          alignItems={'center'}
          animationType={FadeInWhenVisibleBoxAnimationType.SlideLeft}
          boxShadow={15}
          margin={'24px'}
          padding={'24px'}
        >
          <Typography variant='h4' component='h1' gutterBottom align='center'>
            Pricing
          </Typography>
          <Divider
            variant='middle'
            style={{ width: '50%', marginBottom: '1em' }}
          />
          <Box
            width={'60%'}
            display={'flex'}
            flexDirection={'row'}
            justifyContent={'center'}
            alignItems={'center'}
          >
            <PriceTypeToggle
              ref={priceTypeToggleRef}
              options={priceTypeOptions}
              onChange={(type) => {
                setPriceType(type)
              }}
            />
          </Box>

          <Box
            width={'80%'}
            display={'flex'}
            flexDirection={'row'}
            justifyContent={'center'}
            alignItems={'center'}
          >
            <Grid
              container
              spacing={2}
              gap={5}
              display={'flex'}
              justifyContent={'center'}
              alignItems={'center'}
            >
              {/* Essential Price Grid and Box */}
              <Grid item xs={12} sm={4}>
                <Box
                  width={'100%'}
                  padding={'24px'}
                  boxShadow={15}
                  borderRadius={'50px'}
                  display={'flex'}
                  flexDirection={'column'}
                >
                  <Typography
                    variant='h6'
                    component='h1'
                    gutterBottom
                    align='left'
                  >
                    Essential
                  </Typography>
                  <Divider
                    variant='fullWidth'
                    style={{ width: '100%', marginBottom: '1em' }}
                  />
                  <br />
                  <br />
                  <Typography
                    variant='subtitle1'
                    component='h1'
                    gutterBottom
                    align='left'
                  >
                    {'\u2022'} Fundamentals
                  </Typography>
                  <Typography
                    variant='subtitle1'
                    component='h1'
                    gutterBottom
                    align='left'
                  >
                    {'\u2022'} 1GB Database Storage
                  </Typography>
                  <Typography
                    variant='subtitle1'
                    component='h1'
                    gutterBottom
                    align='left'
                  >
                    {'\u2022'} Supports
                  </Typography>
                  <br />
                  <br />
                  <Divider
                    variant='fullWidth'
                    style={{ width: '100%', marginBottom: '1em' }}
                  />
                  {priceType === priceTypeOptions[0] ? (
                    <Typography
                      variant='subtitle1'
                      component='h1'
                      gutterBottom
                      align='left'
                    >
                      HKD 3000 <span>Per month</span>
                    </Typography>
                  ) : (
                    <Typography
                      variant='subtitle1'
                      component='h1'
                      gutterBottom
                      align='left'
                    >
                      HKD 2550 <span>Per month </span> <span>(Save 15%)</span>
                    </Typography>
                  )}

                  <br />
                  <Button
                    variant='outlined'
                    color='primary'
                    sx={{ color: 'white', borderColor: 'white' }}
                    onClick={() => {
                      window.open('mailto:hello@whateverworks.cc', '_blank')
                    }}
                  >
                    Contact Us
                  </Button>
                </Box>
              </Grid>
              {/* Advanced Price Grid and Box */}
              <Grid item xs={12} sm={4} sx={{ color: 'darkgrey' }}>
                <Box
                  width={'100%'}
                  padding={'24px'}
                  boxShadow={15}
                  borderRadius={'50px'}
                  display={'flex'}
                  flexDirection={'column'}
                >
                  <Typography
                    variant='h6'
                    component='h1'
                    gutterBottom
                    align='left'
                  >
                    Advanced
                  </Typography>
                  <Divider
                    variant='fullWidth'
                    style={{ width: '100%', marginBottom: '1em' }}
                  />
                  <br />
                  <br />
                  <Typography
                    variant='subtitle1'
                    component='h1'
                    gutterBottom
                    align='left'
                  >
                    {'\u2022'} Advanced
                  </Typography>
                  <Typography
                    variant='subtitle1'
                    component='h1'
                    gutterBottom
                    align='left'
                  >
                    {'\u2022'} 2GB Database Storage
                  </Typography>
                  <Typography
                    variant='subtitle1'
                    component='h1'
                    gutterBottom
                    align='left'
                  >
                    {'\u2022'} Supports
                  </Typography>
                  <br />
                  <br />
                  <Divider
                    variant='fullWidth'
                    style={{ width: '100%', marginBottom: '1em' }}
                  />
                  {priceType === priceTypeOptions[0] ? (
                    <Typography
                      variant='subtitle1'
                      component='h1'
                      gutterBottom
                      align='left'
                    >
                      (Coming Soon)
                    </Typography>
                  ) : (
                    <Typography
                      variant='subtitle1'
                      component='h1'
                      gutterBottom
                      align='left'
                    >
                      (Coming Soon)
                    </Typography>
                  )}

                  <br />
                  <Button
                    variant='outlined'
                    color='primary'
                    sx={{ color: 'white', borderColor: 'white' }}
                    disabled={true}
                  >
                    Contact Us
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </FadeInWhenVisibleBox>
        <FadeInWhenVisibleBox
          width={'fill-available'}
          height={'fit-content'}
          display={'none'}
          flexDirection={'column'}
          justifyContent={'center'}
          alignItems={'center'}
          animationType={FadeInWhenVisibleBoxAnimationType.SlideRight}
          boxShadow={15}
          margin={'24px'}
          padding={'24px'}
        >
          <Typography variant='h4' component='h1' gutterBottom align='center'>
            Partners
          </Typography>
          <Divider
            variant='middle'
            style={{ width: '50%', marginBottom: '1em' }}
          />
        </FadeInWhenVisibleBox>
        <ProductCommonFooter isShow={true} />
      </Box>
    </>
  )
}

export default CustomizeSolutionMainPage
