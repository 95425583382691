import React from 'react'
import {
  FadeInWhenVisibleBox,
  FadeInWhenVisibleBoxAnimationType,
} from '../../component/common/FadeInWhenVisibleBox'
import ProductCommonFooter from '../product/ProductCommonFooter'
import { Box, Button, Divider, Grid, Typography } from '@mui/material'
import ProductCommonHeader from '../product/ProductCommonHeader'

const TermsOfServicePage: React.FC = () => {
  return (
    <>
      <ProductCommonHeader />
      <Box
        id={'main-box'}
        color={'white'}
        sx={{
          width: '100%',
          height: '100%',
          minHeight: '100vh',
          background: 'black',
        }}
        display={'flex'}
        flexDirection={'column'}
        justifyContent={'flex-start'}
        alignItems={'flex-start'}
        gap={10}
      >
        <Box
          width={'100%'}
          height={'83px'}
          justifyContent={'center'}
          alignItems={'center'}
          maxWidth={'100%'}
        ></Box>

        <FadeInWhenVisibleBox
          width={'fill-available'}
          maxWidth={'100%'}
          height={'auto'}
          display={'flex'}
          flexDirection={'column'}
          justifyContent={'flex-start'}
          alignItems={'flex-start'}
          paddingTop={'2em'}
          paddingBottom={'2em'}
          paddingLeft={'2em'}
          paddingRight={'2em'}
        >
          <Typography variant='h4' component='h1' gutterBottom align='left'>
            Terms of Service
          </Typography>
          <Divider
            variant='middle'
            style={{ width: '50%', marginBottom: '1em' }}
          />
        </FadeInWhenVisibleBox>

        <FadeInWhenVisibleBox
          width={'fill-available'}
          maxWidth={'100%'}
          height={'auto'}
          display={'flex'}
          flexDirection={'column'}
          justifyContent={'flex-start'}
          alignItems={'flex-start'}
          paddingTop={'2em'}
          paddingBottom={'2em'}
          paddingLeft={'2em'}
          paddingRight={'2em'}
        >
          <Typography variant='h4' component='h1' gutterBottom align='left'>
            1. Terms and Conditions
          </Typography>
          <Divider
            variant='middle'
            style={{ width: '50%', marginBottom: '1em' }}
          />
          <Typography
            variant='subtitle1'
            component='p'
            align='left'
            maxWidth={'80%'}
          >
            Please read these terms and conditions carefully as they apply to
            your use of this Internet site (“Site”). By accessing and/or using
            the information and/or materials contained in or published on or
            downloaded from this Site, you acknowledge that you have read and
            understood the following terms and conditions, and you agree to be
            bound by them. We may revise these terms and conditions from time to
            time by updating this posting. There will be no prior notice to you,
            and the revised terms will take effect when they are posted. By
            using the Site, you agree to be bound by any such revisions and
            should, therefore, periodically visit this page to determine the
            current terms and conditions to which you are bound. The information
            and related graphics contained in or published on the Site could
            include inaccuracies or typographical errors. Changes are
            periodically added to the information herein. We may make changes or
            improvements, and/or update the information described herein at any
            time. By using the Site, you agree to be bound by any such revisions
            and should, therefore, periodically visit this page to determine the
            current terms and conditions to which you are bound.
          </Typography>
        </FadeInWhenVisibleBox>

        <FadeInWhenVisibleBox
          width={'fill-available'}
          maxWidth={'100%'}
          height={'auto'}
          display={'flex'}
          flexDirection={'column'}
          justifyContent={'flex-start'}
          alignItems={'flex-start'}
          paddingTop={'2em'}
          paddingBottom={'2em'}
          paddingLeft={'2em'}
          paddingRight={'2em'}
        >
          <Typography variant='h4' component='h1' gutterBottom align='left'>
            2. Registration
          </Typography>
          <Divider
            variant='middle'
            style={{ width: '50%', marginBottom: '1em' }}
          />
          <Typography
            variant='subtitle1'
            component='p'
            align='left'
            maxWidth={'80%'}
          >
            You may be required to register with us in order to access certain
            parts of the Site. Accordingly, you must provide us with accurate,
            complete and updated registration information; and you authorize us
            to assume that any person using the Site with your user name and
            password is either you or is authorized to act for you. By using the
            Site, you agree that you are responsible for maintaining the
            confidentiality of your account and password and for restricting
            access to your computer, and you agree to accept responsibility for
            all activities that occur under your account or password. You
            acknowledge that your use of certain parts of the Site may be
            governed by additional terms and conditions. Where this is the case,
            you will be notified accordingly, and those additional terms and
            conditions: will apply to your use of the relevant parts of the Site
            in addition to these terms and conditions; and will prevail over
            these terms and conditions to the extent of any inconsistency.
          </Typography>
        </FadeInWhenVisibleBox>

        <FadeInWhenVisibleBox
          width={'fill-available'}
          maxWidth={'100%'}
          height={'auto'}
          display={'flex'}
          flexDirection={'column'}
          justifyContent={'flex-start'}
          alignItems={'flex-start'}
          paddingTop={'2em'}
          paddingBottom={'2em'}
          paddingLeft={'2em'}
          paddingRight={'2em'}
        >
          <Typography variant='h4' component='h1' gutterBottom align='left'>
            3. Services
          </Typography>
          <Divider
            variant='middle'
            style={{ width: '50%', marginBottom: '1em' }}
          />
          <Typography
            variant='subtitle1'
            component='p'
            align='left'
            maxWidth={'80%'}
          >
            You acknowledge that we may, in our sole discretion and with or
            without notice: vary the Site or any part of the Site; and modify or
            discontinue the Site, any part of the Site and the services
            available on it without prior notice to you.
          </Typography>
        </FadeInWhenVisibleBox>

        <FadeInWhenVisibleBox
          width={'fill-available'}
          maxWidth={'100%'}
          height={'auto'}
          display={'flex'}
          flexDirection={'column'}
          justifyContent={'flex-start'}
          alignItems={'flex-start'}
          paddingTop={'2em'}
          paddingBottom={'2em'}
          paddingLeft={'2em'}
          paddingRight={'2em'}
        >
          <Typography variant='h4' component='h1' gutterBottom align='left'>
            4. Site Content
          </Typography>
          <Divider
            variant='middle'
            style={{ width: '50%', marginBottom: '1em' }}
          />
          <Typography
            variant='subtitle1'
            component='p'
            align='left'
            maxWidth={'80%'}
          >
            All editorial content, graphics, multimedia works and other content
            on this Site are protected by copyright laws and/or trademark laws
            and/or other laws and/or international treaties. These works, logos,
            graphics, sounds or images may not be copied, reproduced or imitated
            whether in whole or in part, unless expressly permitted by us.
          </Typography>
        </FadeInWhenVisibleBox>

        <FadeInWhenVisibleBox
          width={'fill-available'}
          maxWidth={'100%'}
          height={'auto'}
          display={'flex'}
          flexDirection={'column'}
          justifyContent={'flex-start'}
          alignItems={'flex-start'}
          paddingTop={'2em'}
          paddingBottom={'2em'}
          paddingLeft={'2em'}
          paddingRight={'2em'}
        >
          <Typography variant='h4' component='h1' gutterBottom align='left'>
            5. Your Use of the Site
          </Typography>
          <Divider
            variant='middle'
            style={{ width: '50%', marginBottom: '1em' }}
          />
          <Typography
            variant='subtitle1'
            component='p'
            align='left'
            maxWidth={'80%'}
          >
            We license you to browse the Site and, for informational or personal
            purposes, to print or download the materials at the Site. No other
            right is granted. Use for any other purpose is expressly prohibited,
            and may result in severe civil and criminal penalties. Violators
            will be prosecuted to the maximum extent possible under the law.
          </Typography>
          <br />
          <Typography
            variant='subtitle1'
            component='p'
            align='left'
            maxWidth={'80%'}
          >
            You must retain all copyright and other proprietary notices
            contained in the original materials on any copies of the materials.
            In particular, you must ensure that the copyright notice below
            appears in all copies, as follows:
          </Typography>
          <br />
          <Typography
            variant='subtitle1'
            component='p'
            align='left'
            maxWidth={'80%'}
          >
            COPYRIGHT NOTICE: “Copyright © 2023 Whatever Works Development
            Limited. All rights reserved.”
          </Typography>
          <br />
          <Typography
            variant='subtitle1'
            component='p'
            align='left'
            maxWidth={'80%'}
          >
            You may not alter, add or modify the materials at the Site in any
            way, reproduce or publicly display, distribute or otherwise use such
            materials for any public or unauthorized purpose or for any
            commercial gain.
          </Typography>
          <br />
          <Typography
            variant='subtitle1'
            component='p'
            align='left'
            maxWidth={'80%'}
          >
            You further undertake not to post or transmit to or from the Site
            any unlawful, threatening, libelous, defamatory, obscene,
            scandalous, inflammatory, pornographic, or profane material, or any
            other material which may not be lawfully disseminated under the laws
            of Hong Kong or any other relevant country or that could give rise
            to any civil or criminal liability under any Applicable Law.
          </Typography>
          <br />
          <Typography
            variant='subtitle1'
            component='p'
            align='left'
            maxWidth={'80%'}
          >
            You must not disrupt or interfere with the operation of the Site or
            servers or other software, hardware or equipment connected to or via
            the Site, violate any Applicable Law relating to your use of the
            Site, or collect or store personal data about other users of the
            Site.
          </Typography>
        </FadeInWhenVisibleBox>

        <FadeInWhenVisibleBox
          width={'fill-available'}
          maxWidth={'100%'}
          height={'auto'}
          display={'flex'}
          flexDirection={'column'}
          justifyContent={'flex-start'}
          alignItems={'flex-start'}
          paddingTop={'2em'}
          paddingBottom={'2em'}
          paddingLeft={'2em'}
          paddingRight={'2em'}
        >
          <Typography variant='h4' component='h1' gutterBottom align='left'>
            6. Links
          </Typography>
          <Divider
            variant='middle'
            style={{ width: '50%', marginBottom: '1em' }}
          />
          <Typography
            variant='subtitle1'
            component='p'
            align='left'
            maxWidth={'80%'}
          >
            You may not link this Website to any other Website without our prior
            written consent. Where we provide links to a third-party website, we
            do not take any responsibility for or make any warranties,
            representations or undertakings about the content of any other
            website which may be referred to or accessed by hypertext links in,
            from or with this Website, and we do not endorse or approve the
            content of such third-party websites.
          </Typography>
        </FadeInWhenVisibleBox>

        <FadeInWhenVisibleBox
          width={'fill-available'}
          maxWidth={'100%'}
          height={'auto'}
          display={'flex'}
          flexDirection={'column'}
          justifyContent={'flex-start'}
          alignItems={'flex-start'}
          paddingTop={'2em'}
          paddingBottom={'2em'}
          paddingLeft={'2em'}
          paddingRight={'2em'}
        >
          <Typography variant='h4' component='h1' gutterBottom align='left'>
            7. Personal Data and Cookies
          </Typography>
          <Divider
            variant='middle'
            style={{ width: '50%', marginBottom: '1em' }}
          />
          <Typography
            variant='subtitle1'
            component='p'
            align='left'
            maxWidth={'80%'}
          >
            In using the Site, you may give us “personal data/information” which
            can be used to identify individuals. By using the Site, you have
            granted us the required consent to use your personal
            data/information in accordance with our Privacy Policy/Statement.
            Please click this link to view our Privacy Policy/Statement.
          </Typography>
          <br />
          <Typography
            variant='subtitle1'
            component='p'
            align='left'
            maxWidth={'80%'}
          >
            During your use of the Site, we may issue to and request from your
            computer blocks of data known as “cookies”. These cookies help us to
            analyze web traffic and to make improvements to the Site. You must
            not alter any cookies sent to your computer from the Site, and you
            must ensure that your computer sends correct and accurate cookies in
            response to any relevant request from the Site.
          </Typography>
        </FadeInWhenVisibleBox>

        <FadeInWhenVisibleBox
          width={'fill-available'}
          maxWidth={'100%'}
          height={'auto'}
          display={'flex'}
          flexDirection={'column'}
          justifyContent={'flex-start'}
          alignItems={'flex-start'}
          paddingTop={'2em'}
          paddingBottom={'2em'}
          paddingLeft={'2em'}
          paddingRight={'2em'}
        >
          <Typography variant='h4' component='h1' gutterBottom align='left'>
            8. Liability and Indemnity
          </Typography>
          <Divider
            variant='middle'
            style={{ width: '50%', marginBottom: '1em' }}
          />
          <Typography
            variant='subtitle1'
            component='p'
            align='left'
            maxWidth={'80%'}
          >
            You shall irrevocably indemnify us and our affiliates from and
            against any and all losses, damages and costs suffered or incurred
            by us, or our affiliates of whatsoever nature arising out of or in
            connection with your use, provision or distribution of information
            on the Website or otherwise, howsoever arising, in relation to any
            breach of these Terms and Conditions by you.
          </Typography>
          <br />
          <Typography
            variant='subtitle1'
            component='p'
            align='left'
            maxWidth={'80%'}
          >
            We accept liability without limit for, death or personal injury
            caused by our negligence, any fraudulent pre-contractual
            misrepresentations made by us on which you can be shown to have
            relied, and for any other form of liability which cannot legally be
            limited or excluded.
          </Typography>
          <br />
          <Typography
            variant='subtitle1'
            component='p'
            align='left'
            maxWidth={'80%'}
          >
            The Website, including without limitation, its contents,
            functionality, performance and features is provided on an “as
            available basis” at your sole risk and without representations or
            warranties of any kind and all warranties, conditions or terms
            (express or implied, statutory or otherwise including without
            limitation any warranty as to satisfactory quality or any warranty
            or commitment that access or use will be uninterrupted or error
            free) are expressly excluded.
          </Typography>
          <br />
          <Typography
            variant='subtitle1'
            component='p'
            align='left'
            maxWidth={'80%'}
          >
            Accordingly, we shall not be liable for any losses (including
            direct, indirect, incidental, special or consequential damages)
            arising out of use of or inability to access the Website. It shall
            be your responsibility to make all necessary copies and backups of
            any information obtained or available from the Website. Accordingly,
            you agree not to bring a claim of any nature against us in relation
            to your use of or access to the Website except where such a claim
            cannot be excluded by law. You acknowledge and agree that we will
            have the right (subject to the discretion of the Court) to a stay of
            proceedings if you bring any claim against us in breach of the
            foregoing.
          </Typography>
        </FadeInWhenVisibleBox>

        <FadeInWhenVisibleBox
          width={'fill-available'}
          maxWidth={'100%'}
          height={'auto'}
          display={'flex'}
          flexDirection={'column'}
          justifyContent={'flex-start'}
          alignItems={'flex-start'}
          paddingTop={'2em'}
          paddingBottom={'2em'}
          paddingLeft={'2em'}
          paddingRight={'2em'}
        >
          <Typography variant='h4' component='h1' gutterBottom align='left'>
            9. Limitation of Liability
          </Typography>
          <Divider
            variant='middle'
            style={{ width: '50%', marginBottom: '1em' }}
          />
          <Typography
            variant='subtitle1'
            component='p'
            align='left'
            maxWidth={'80%'}
          >
            (a) To the extent permitted by law, neither we nor any other party
            involved in creating, producing or delivering the Site or any
            Content will be liable for any incidental, consequential or indirect
            loss or damages, including, but not limited to, loss or corruption
            of data, loss of profits, goodwill, bargain or opportunity or loss
            of anticipated savings resulting from your access to, or use of, or
            inability to use the Site and the Content, whether based on
            warranty, contract, tort, negligence or any other legal theory, and
            whether or not we know of the possibility of such damage.
          </Typography>
          <br />
          <Typography
            variant='subtitle1'
            component='p'
            align='left'
            maxWidth={'80%'}
          >
            (b) If a jurisdiction does not allow the exclusion or limitation of
            liability in accordance with paragraph (a) but allows a limitation
            of a certain maximum extent, then our liability is limited to that
            extent.
          </Typography>
        </FadeInWhenVisibleBox>

        <FadeInWhenVisibleBox
          width={'fill-available'}
          maxWidth={'100%'}
          height={'auto'}
          display={'flex'}
          flexDirection={'column'}
          justifyContent={'flex-start'}
          alignItems={'flex-start'}
          paddingTop={'2em'}
          paddingBottom={'2em'}
          paddingLeft={'2em'}
          paddingRight={'2em'}
        >
          <Typography variant='h4' component='h1' gutterBottom align='left'>
            10. Cancellation
          </Typography>
          <Divider
            variant='middle'
            style={{ width: '50%', marginBottom: '1em' }}
          />
          <Typography
            variant='subtitle1'
            component='p'
            align='left'
            maxWidth={'80%'}
          >
            You may cancel your registration at any time by notifying us.
          </Typography>
          <br />
          <Typography
            variant='subtitle1'
            component='p'
            align='left'
            maxWidth={'80%'}
          >
            We may cancel your registration if you do not visit the Site for an
            extended period of time, or if we reasonably believe that you have
            violated any Applicable Laws, acted inconsistently with the letter
            or spirit of this agreement or violated our rights or those of
            another party.
          </Typography>
          <br />
          <Typography
            variant='subtitle1'
            component='p'
            align='left'
            maxWidth={'80%'}
          >
            The provisions of this agreement entitled “Disclaimer”, “Limitation
            of Liability” and “General” will survive cancellation of your
            registration or termination of this agreement.
          </Typography>
        </FadeInWhenVisibleBox>

        <FadeInWhenVisibleBox
          width={'fill-available'}
          maxWidth={'100%'}
          height={'auto'}
          display={'flex'}
          flexDirection={'column'}
          justifyContent={'flex-start'}
          alignItems={'flex-start'}
          paddingTop={'2em'}
          paddingBottom={'2em'}
          paddingLeft={'2em'}
          paddingRight={'2em'}
        >
          <Typography variant='h4' component='h1' gutterBottom align='left'>
            11. General Provisions
          </Typography>
          <Typography
            variant='subtitle1'
            component='p'
            align='left'
            maxWidth={'80%'}
          >
            You may not transfer your rights or obligations under these Terms
            and Conditions without our prior written consent.
          </Typography>
          <br />
          <Typography
            variant='subtitle1'
            component='p'
            align='left'
            maxWidth={'80%'}
          >
            We will not be liable to you for failure to fulfill obligations
            caused by events outside our control, including without limitation,
            any difficulties experienced by you in accessing this Website.
          </Typography>
          <br />
          <Typography
            variant='subtitle1'
            component='p'
            align='left'
            maxWidth={'80%'}
          >
            Your use of this Website is governed by the laws of the Hong Kong
            SAR and you agree to submit to the non-exclusive jurisdiction of the
            Courts of the Hong Kong SAR in relation to any dispute arising out
            of your use of or access to it.
          </Typography>
          <br />
          <Typography
            variant='subtitle1'
            component='p'
            align='left'
            maxWidth={'80%'}
          >
            These Terms and Conditions constitute the entire agreement between
            us relating to your use of this Website and shall supersede and
            replace all other contracts (except, where applicable), discussions,
            letters or communications (whether written or oral) relating to the
            subject matter hereof save that neither party excludes liability for
            any fraudulent misrepresentations made by it to the other party upon
            which the other party can be shown to have relied.
          </Typography>
        </FadeInWhenVisibleBox>

        <FadeInWhenVisibleBox
          width={'fill-available'}
          maxWidth={'100%'}
          height={'auto'}
          display={'flex'}
          flexDirection={'column'}
          justifyContent={'flex-start'}
          alignItems={'flex-start'}
          paddingTop={'2em'}
          paddingBottom={'2em'}
          paddingLeft={'2em'}
          paddingRight={'2em'}
        >
          <Typography
            variant='h4'
            component='h1'
            gutterBottom
            align='left'
          ></Typography>
          <Divider
            variant='middle'
            style={{ width: '50%', marginBottom: '1em' }}
          />
          <br />
          <Typography
            variant='subtitle1'
            component='p'
            align='left'
            maxWidth={'80%'}
          >
            Date Date of Update: 15 Aug 2023
          </Typography>
        </FadeInWhenVisibleBox>

        <ProductCommonFooter isShow={true} />
      </Box>
    </>
  )
}

export default TermsOfServicePage
