// CompanyInfoCard.tsx
import React from 'react'
import { Box, CardContent, CardProps, Typography } from '@mui/material'
import AnimatedFloatingCard from '../../component/card/AnimatedFloatingCard'

interface CompanyStartProps extends CardProps {
  id?: string
}

const CompanyStartCard: React.FC<CompanyStartProps> = ({
  id,
}: CompanyStartProps) => {
  return (
    <AnimatedFloatingCard elevation={4} id={id}>
      <CardContent
        style={{
          overflow: undefined,
        }}
      >
        <Typography
          variant='h4'
          component='h2'
          gutterBottom
          align='center'
          textTransform={'uppercase'}
        >
          We are
        </Typography>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
          }}
        >
          <img
            src='/images/logo/white-font-logo.png'
            alt='logo'
            style={{ width: 'auto', height: '100%' }}
          />
        </Box>
      </CardContent>
    </AnimatedFloatingCard>
  )
}

export default CompanyStartCard
