import { Box, BoxProps, Button, Divider, Grid, Typography } from '@mui/material'
import React, { ReactNode } from 'react'
import ProductCommonHeader from '../ProductCommonHeader'
import ProductCommonFooter from '../ProductCommonFooter'
import {
  FadeInWhenVisibleBox,
  FadeInWhenVisibleBoxAnimationType,
} from '../../../component/common/FadeInWhenVisibleBox'
import PriceTypeToggle, {
  PriceTypeToggleRef,
} from '../../../component/toggle/PriceTypeToggle'

const AMSMainPage: React.FC = () => {
  // Local variables
  const priceTypeOptions = ['Monthly', 'Yearly']

  // Local ref
  const priceTypeToggleRef = React.useRef<PriceTypeToggleRef>(null)

  // Local state
  const [priceType, setPriceType] = React.useState<string>(priceTypeOptions[0])

  return (
    <>
      <ProductCommonHeader />
      <Box
        id={'main-box'}
        color={'white'}
        sx={{
          width: '100%',
          height: '100%',
          minHeight: '100vh',
          background: 'transparent',
          backgroundImage: 'url(./images/common/background-02.png)',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          maxWidth: '100%',
          backgroundAttachment: 'fixed',
          overflowX: 'hidden',
        }}
        display={'flex'}
        flexDirection={'column'}
        justifyContent={'flex-start'}
        alignItems={'flex-start'}
        gap={10}
      >
        <Box
          width={'100%'}
          height={'83px'}
          justifyContent={'center'}
          alignItems={'center'}
          maxWidth={'100%'}
        ></Box>

        <Box
          width={'100%'}
          maxWidth={'100%'}
          height={'auto'}
          display={'flex'}
          flexDirection={'column'}
          justifyContent={'center'}
          alignItems={'center'}
          paddingTop={'2em'}
          paddingBottom={'2em'}
        >
          <Typography variant='h4' component='h1' gutterBottom align='center'>
            Assets Management System
          </Typography>
          <Divider
            variant='middle'
            style={{ width: '50%', marginBottom: '1em' }}
          />
          <Typography
            variant='subtitle1'
            component='p'
            align='center'
            maxWidth={'80%'}
          >
            Unleash your business potential with our comprehensive Assets
            Management System. Designed specifically for SMEs, our platform is
            your ultimate ally in navigating asset management with ease and
            efficiency.
          </Typography>
          <br />
          <br />
          <Box
            width={'30%'}
            sx={{
              cursor: 'pointer',
            }}
            onClick={() => {
              window.open('https://ams-demo.whateverworks.cc', '_blank')
            }}
          >
            <img src='./images/ww-wms/preview_1.png' alt='ams' width={'100%'} />
          </Box>
        </Box>

        <FadeInWhenVisibleBox
          width={'fill-available'}
          height={'fit-content'}
          display={'flex'}
          flexDirection={'column'}
          justifyContent={'center'}
          alignItems={'center'}
          animationType={FadeInWhenVisibleBoxAnimationType.ZoomIn}
          boxShadow={15}
          margin={'24px'}
          padding={'24px'}
        >
          <Typography variant='h4' component='h1' gutterBottom align='center'>
            Why us?
          </Typography>
          <Divider
            variant='middle'
            style={{ width: '50%', marginBottom: '1em' }}
          />

          <Typography
            variant='subtitle1'
            component='p'
            align='center'
            maxWidth={'80%'}
          >
            {`We offer a Asset Management System that seamlessly combines user-friendliness with advanced functionality. Crafted by a team of forward-thinking developers, our platform is continuously evolving to stay ahead of market trends and cater to our clients' dynamic needs.`}
          </Typography>
          <Typography
            variant='subtitle1'
            component='p'
            align='center'
            maxWidth={'80%'}
          >
            {`Moreover, our strategic partnership with a seasoned industry leader, boasting over 30 years of experience, empowers us to deliver top-tier hardware and software solutions, backed by unparalleled support services. Choose us for an assets management experience that is efficient, adaptive, and reliable.`}
          </Typography>
        </FadeInWhenVisibleBox>

        <FadeInWhenVisibleBox
          width={'fill-available'}
          height={'fit-content'}
          display={'flex'}
          flexDirection={'column'}
          justifyContent={'center'}
          alignItems={'center'}
          animationType={FadeInWhenVisibleBoxAnimationType.ZoomIn}
          boxShadow={15}
          margin={'24px'}
          padding={'24px'}
        >
          <Typography variant='h4' component='h1' gutterBottom align='center'>
            Features
          </Typography>
          <Divider
            variant='middle'
            style={{ width: '50%', marginBottom: '1em' }}
          />

          <Box
            width={'60%'}
            display={'flex'}
            flexDirection={'row'}
            flexWrap='wrap'
            gap={5}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <Box width={'100%'}>
                  <Typography
                    variant='h6'
                    component='h1'
                    gutterBottom
                    align='left'
                  >
                    Fundamentals
                  </Typography>
                  <Divider
                    variant='fullWidth'
                    style={{ width: '100%', marginBottom: '1em' }}
                  />
                  <Typography
                    variant='subtitle1'
                    component='h1'
                    gutterBottom
                    align='left'
                  >
                    {'\u2022'} Assets Library
                  </Typography>
                  <Typography
                    variant='subtitle1'
                    component='h1'
                    gutterBottom
                    align='left'
                  >
                    {'\u2022'} Hardware Scanning
                  </Typography>
                  <Typography
                    variant='subtitle1'
                    component='h1'
                    gutterBottom
                    align='left'
                  >
                    {'\u2022'} Checkout / Return
                  </Typography>
                  <Typography
                    variant='subtitle1'
                    component='h1'
                    gutterBottom
                    align='left'
                  >
                    {'\u2022'} Reserve / Release
                  </Typography>
                  <Typography
                    variant='subtitle1'
                    component='h1'
                    gutterBottom
                    align='left'
                  >
                    {'\u2022'} Stock Take / Audit
                  </Typography>
                  <Typography
                    variant='subtitle1'
                    component='h1'
                    gutterBottom
                    align='left'
                  >
                    {'\u2022'} History / Logs
                  </Typography>
                  <Typography
                    variant='subtitle1'
                    component='h1'
                    gutterBottom
                    align='left'
                  >
                    {'\u2022'} Reports
                  </Typography>
                  <Typography
                    variant='subtitle1'
                    component='h1'
                    gutterBottom
                    align='left'
                    color={'darkgrey'}
                  >
                    {'\u2022'} Multilingual Support (Coming Soon)
                  </Typography>
                  <br />
                  <br />
                  <Button
                    variant='contained'
                    color='primary'
                    onClick={() => {
                      window.open('https://ams-demo.whateverworks.cc', '_blank')
                    }}
                  >
                    Demo Trial
                  </Button>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Box width={'100%'} color={'darkgrey'}>
                  <Typography
                    variant='h6'
                    component='h1'
                    gutterBottom
                    align='left'
                  >
                    Advanced
                  </Typography>
                  <Divider
                    variant='fullWidth'
                    style={{ width: '100%', marginBottom: '1em' }}
                  />
                  <Typography
                    variant='subtitle1'
                    component='h1'
                    gutterBottom
                    align='left'
                  >
                    {'\u2022'} Multi-user Access (Coming Soon)
                  </Typography>
                  <Typography
                    variant='subtitle1'
                    component='h1'
                    gutterBottom
                    align='left'
                  >
                    {'\u2022'} User-based Access Control (Coming Soon)
                  </Typography>
                  <Typography
                    variant='subtitle1'
                    component='h1'
                    gutterBottom
                    align='left'
                  >
                    {'\u2022'} API Integration (Coming Soon)
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </FadeInWhenVisibleBox>

        <FadeInWhenVisibleBox
          width={'fill-available'}
          height={'fit-content'}
          display={'none'}
          flexDirection={'column'}
          justifyContent={'center'}
          alignItems={'center'}
          animationType={FadeInWhenVisibleBoxAnimationType.SlideLeft}
          boxShadow={15}
          margin={'24px'}
          padding={'24px'}
        >
          <Typography variant='h4' component='h1' gutterBottom align='center'>
            Pricing
          </Typography>
          <Divider
            variant='middle'
            style={{ width: '50%', marginBottom: '1em' }}
          />
          <Box
            width={'60%'}
            display={'flex'}
            flexDirection={'row'}
            justifyContent={'center'}
            alignItems={'center'}
          >
            <PriceTypeToggle
              ref={priceTypeToggleRef}
              options={priceTypeOptions}
              onChange={(type) => {
                setPriceType(type)
              }}
            />
          </Box>

          <Box
            width={'80%'}
            display={'flex'}
            flexDirection={'row'}
            justifyContent={'center'}
            alignItems={'center'}
          >
            <Grid
              container
              spacing={2}
              gap={5}
              display={'flex'}
              justifyContent={'center'}
              alignItems={'center'}
            >
              {/* Essential Price Grid and Box */}
              <Grid item xs={12} sm={4}>
                <Box
                  width={'100%'}
                  padding={'24px'}
                  boxShadow={15}
                  borderRadius={'50px'}
                  display={'flex'}
                  flexDirection={'column'}
                >
                  <Typography
                    variant='h6'
                    component='h1'
                    gutterBottom
                    align='left'
                  >
                    Essential
                  </Typography>
                  <Divider
                    variant='fullWidth'
                    style={{ width: '100%', marginBottom: '1em' }}
                  />
                  <br />
                  <br />
                  <Typography
                    variant='subtitle1'
                    component='h1'
                    gutterBottom
                    align='left'
                  >
                    {'\u2022'} Fundamentals
                  </Typography>
                  <Typography
                    variant='subtitle1'
                    component='h1'
                    gutterBottom
                    align='left'
                  >
                    {'\u2022'} 1GB Database Storage
                  </Typography>
                  <Typography
                    variant='subtitle1'
                    component='h1'
                    gutterBottom
                    align='left'
                  >
                    {'\u2022'} Supports
                  </Typography>
                  <br />
                  <br />
                  <Divider
                    variant='fullWidth'
                    style={{ width: '100%', marginBottom: '1em' }}
                  />
                  {priceType === priceTypeOptions[0] ? (
                    <Typography
                      variant='subtitle1'
                      component='h1'
                      gutterBottom
                      align='left'
                    >
                      HKD 3000 <span>Per month</span>
                    </Typography>
                  ) : (
                    <Typography
                      variant='subtitle1'
                      component='h1'
                      gutterBottom
                      align='left'
                    >
                      HKD 2550 <span>Per month </span> <span>(Save 15%)</span>
                    </Typography>
                  )}

                  <br />
                  <Button
                    variant='outlined'
                    color='primary'
                    sx={{ color: 'white', borderColor: 'white' }}
                    onClick={() => {
                      window.open('mailto:hello@whateverworks.cc', '_blank')
                    }}
                  >
                    Contact Us
                  </Button>
                </Box>
              </Grid>
              {/* Advanced Price Grid and Box */}
              <Grid item xs={12} sm={4} sx={{ color: 'darkgrey' }}>
                <Box
                  width={'100%'}
                  padding={'24px'}
                  boxShadow={15}
                  borderRadius={'50px'}
                  display={'flex'}
                  flexDirection={'column'}
                >
                  <Typography
                    variant='h6'
                    component='h1'
                    gutterBottom
                    align='left'
                  >
                    Advanced
                  </Typography>
                  <Divider
                    variant='fullWidth'
                    style={{ width: '100%', marginBottom: '1em' }}
                  />
                  <br />
                  <br />
                  <Typography
                    variant='subtitle1'
                    component='h1'
                    gutterBottom
                    align='left'
                  >
                    {'\u2022'} Advanced
                  </Typography>
                  <Typography
                    variant='subtitle1'
                    component='h1'
                    gutterBottom
                    align='left'
                  >
                    {'\u2022'} 2GB Database Storage
                  </Typography>
                  <Typography
                    variant='subtitle1'
                    component='h1'
                    gutterBottom
                    align='left'
                  >
                    {'\u2022'} Supports
                  </Typography>
                  <br />
                  <br />
                  <Divider
                    variant='fullWidth'
                    style={{ width: '100%', marginBottom: '1em' }}
                  />
                  {priceType === priceTypeOptions[0] ? (
                    <Typography
                      variant='subtitle1'
                      component='h1'
                      gutterBottom
                      align='left'
                    >
                      (Coming Soon)
                    </Typography>
                  ) : (
                    <Typography
                      variant='subtitle1'
                      component='h1'
                      gutterBottom
                      align='left'
                    >
                      (Coming Soon)
                    </Typography>
                  )}

                  <br />
                  <Button
                    variant='outlined'
                    color='primary'
                    sx={{ color: 'white', borderColor: 'white' }}
                    disabled={true}
                  >
                    Contact Us
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </FadeInWhenVisibleBox>
        <FadeInWhenVisibleBox
          width={'fill-available'}
          height={'fit-content'}
          display={'none'}
          flexDirection={'column'}
          justifyContent={'center'}
          alignItems={'center'}
          animationType={FadeInWhenVisibleBoxAnimationType.SlideRight}
          boxShadow={15}
          margin={'24px'}
          padding={'24px'}
        >
          <Typography variant='h4' component='h1' gutterBottom align='center'>
            Partners
          </Typography>
          <Divider
            variant='middle'
            style={{ width: '50%', marginBottom: '1em' }}
          />
        </FadeInWhenVisibleBox>
        <ProductCommonFooter isShow={true} />
      </Box>
    </>
  )
}

export default AMSMainPage
