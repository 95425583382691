// CompanyInfoCard.tsx
import React from 'react'
import {
  Box,
  CardContent,
  CardProps,
  Divider,
  Grid,
  Typography,
} from '@mui/material'
import AnimatedFloatingCard from '../../component/card/AnimatedFloatingCard'
import GalleryViewerItem from '../../component/gallery/GalleryViewerItem'
import GalleryViewer from '../../component/gallery/GalleryViewer'

interface CompanyGalleryProps extends CardProps {
  id?: string
}

const CompanyGalleryCard: React.FC<CompanyGalleryProps> = ({
  id,
}: CompanyGalleryProps) => {
  return (
    <AnimatedFloatingCard elevation={4} id={id}>
      <CardContent>
        <Box>
          <Typography
            variant='h4'
            component='h2'
            gutterBottom
            align='center'
            textTransform={'uppercase'}
          >
            Our Services
          </Typography>
          <Typography variant='body1' component='p' gutterBottom align='center'>
            We provide a wide range of services to help you grow your business
          </Typography>
          <Typography variant='body1' component='p' gutterBottom align='center'>
            We are also open to any ideas that you have in mind
          </Typography>
          <br />
          <Grid
            container
            spacing={2}
            display={'flex'}
            flexDirection={'row'}
            width={'100%'}
          >
            <Grid item xs={12} sm={6} alignItems={'left'}>
              <Typography variant='h6' component='h2' gutterBottom>
                SME Solutions
              </Typography>
              <Divider />
              <br />
              <Typography
                variant='body1'
                component='p'
                gutterBottom
                sx={{
                  '&:hover': {
                    cursor: 'pointer',
                    textShadow: '0px 0px 10px #000000',
                  },
                }}
                onClick={() => {
                  window.location.href = '/ams'
                }}
              >
                {'\u2022'} Assets Management System
              </Typography>
              <Typography
                variant='body1'
                component='p'
                gutterBottom
                color={'darkgrey'}
              >
                {'\u2022'} Warehouse Management System (Coming Soon)
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant='h6' component='h2' gutterBottom>
                Customized Solutions
              </Typography>
              <Divider />
              <br />
              <Typography
                variant='body1'
                component='p'
                gutterBottom
                sx={{
                  '&:hover': {
                    cursor: 'pointer',
                    textShadow: '0px 0px 10px #000000',
                  },
                }}
                onClick={() => {
                  window.location.href = '/customize-solution'
                }}
              >
                {'\u2022'} Tailor-Make Your Ideas
              </Typography>
              <Typography
                variant='body1'
                component='p'
                gutterBottom
                sx={{
                  '&:hover': {
                    cursor: 'pointer',
                    textShadow: '0px 0px 10px #000000',
                  },
                }}
                onClick={() => {
                  window.location.href = '/self-host-solution'
                }}
              >
                {'\u2022'} Business Self-host Solution
              </Typography>
            </Grid>
          </Grid>

          {/* <GalleryViewer>
            <GalleryViewerItem
              image='/images/ww-wms/preview_1.png'
              title='Assets Management System'
              description='Simple and easy to use assets management system'
              link='/ams'
              comingSoon={false}
            />
            <GalleryViewerItem
              image='/images/customize/tailor-make-preview.jpg'
              title='Ideas Tailor-Make'
              description='Tailor-make your ideas into reality'
              link='/tailor-make'
              comingSoon={false}
            />
            <GalleryViewerItem
              image='/images/ww-wms/preview_1.png'
              title='Warehouse Management System'
              description='1'
              link='#'
              comingSoon={true}
            />
            <GalleryViewerItem image='' title='' description='1' link='#' />
          </GalleryViewer> */}
        </Box>
      </CardContent>
    </AnimatedFloatingCard>
  )
}

export default CompanyGalleryCard
