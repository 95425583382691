import { Layout } from 'antd'
import RedDotCursor from '../../component/cursor/RedDotCursor'
import MainParentFrame from '../../component/frame/MainParentFrame'
import SectionPageWrapper from '../../component/frame/SectionPageWrapper'
import CompanyContactCard from './CompanyContactCard'
import CompanyFooter from './CompanyFooter'
import CompanyGalleryCard from './CompanyGalleryCard'
import CompanyHeader from './CompanyHeader'
import CompanyInfoCard from './CompanyInfoCard'
import CompanySloganCard from './CompanySloganCard'
import CompanyWhyUsCard from './CompanyWhyUsCard'
import React from 'react'
import Snowfall from 'react-snowfall'
import CompanyStartCard from './CompanyStartCard'

const MainPage = (): JSX.Element => {
  return (
    <>
      <RedDotCursor />
      <CompanyHeader />
      <MainParentFrame isDarkMode={true}>
        <Snowfall />
        <Layout
          style={{
            height: 'calc(100vh - 430px)',
            width: '100%',
            backgroundColor: 'transparent',
          }}
        >
          <SectionPageWrapper
            starter={<CompanyStartCard id='companyLogoCard' />}
            terminator={<CompanyContactCard id='companyContactCard' />}
          >
            <CompanyInfoCard id='companyInfoCard' />
            <CompanyWhyUsCard id='companyWhyUsCard' />
            <CompanySloganCard id='companySloganCard' />
            <CompanyGalleryCard id='companyGalleryCard' />
          </SectionPageWrapper>
        </Layout>
        <CompanyFooter id='companyFooter' isShow={true} />
      </MainParentFrame>
    </>
  )
}

export default MainPage
