// CompanyInfoCard.tsx
import React from 'react'
import { Box, CardContent, CardProps, Typography } from '@mui/material'
import AnimatedFloatingCard from '../../component/card/AnimatedFloatingCard'

interface CompanyInfoProps extends CardProps {
  id?: string
}

const CompanyInfoCard: React.FC<CompanyInfoProps> = ({
  id,
}: CompanyInfoProps) => {
  return (
    <AnimatedFloatingCard elevation={4} id={id}>
      <CardContent>
        <Box mb={3}>
          <Typography
            variant='h4'
            component='h2'
            gutterBottom
            align='center'
            textTransform={'uppercase'}
          >
            Our Story
          </Typography>
          <Typography variant='body1' paragraph align='center'>
            Whatever Works is a cutting-edge software development and consulting
            company specializing in tailor-made software products, web
            development, and cloud computing. Our team of seasoned professionals
            is dedicated to delivering innovative and reliable solutions for
            businesses of all sizes, helping them streamline processes, enhance
            productivity, and reach new heights.
          </Typography>
          <Typography variant='body1' paragraph align='center'>
            By combining deep industry knowledge with technical expertise,{' '}
            <br />
            Whatever Works is the partner you need to navigate the ever-evolving
            digital landscape.
          </Typography>
        </Box>
      </CardContent>
    </AnimatedFloatingCard>
  )
}

export default CompanyInfoCard
