import { MailOutline } from '@mui/icons-material'
import { Box, Divider, Grid, IconButton, Link, Typography } from '@mui/material'
import React from 'react'
import LinkedInIcon from '@mui/icons-material/LinkedIn'

interface ProductCommonFooterProps {
  id?: string
  isShow?: boolean
}

const ProductCommonFooter: React.FC<ProductCommonFooterProps> = ({
  id,
  isShow,
}: ProductCommonFooterProps) => {
  return (
    <Box
      width={'100%'}
      position={'relative'}
      bottom={0}
      height={isShow ? 'fit-content' : '0px'}
      overflow={'hidden'}
      maxHeight={'400px'}
      sx={{
        background: 'transparent',
      }}
    >
      <Box
        boxShadow={15}
        padding={'16px'}
        marginTop={'16px'}
        marginLeft={'16px'}
        marginRight={'16px'}
        sx={{
          background: 'transparent',
          backdropFilter: 'blur(10px)',
          color: 'darkgrey',
        }}
        display={'flex'}
        flexDirection={'column'}
      >
        <Grid container spacing={2} color={'white'}>
          <Grid item xs={12} sm={9}>
            {/* Directory Map */}
            <Box
              display='flex'
              flexDirection='row'
              gap={1}
              justifyContent={'flex-start'}
            >
              <Box
                display={'flex'}
                flexDirection={'column'}
                gap={1}
                justifyContent={'flex-start'}
              >
                <Typography
                  variant='h6'
                  component='h2'
                  gutterBottom
                  align='left'
                >
                  SME Solutions
                </Typography>
                <Typography
                  variant='body2'
                  component='span'
                  gutterBottom
                  align='left'
                >
                  <Link
                    href='/ams'
                    sx={{
                      textDecoration: 'none',
                      color: 'inherit',
                      '&:hover': {
                        cursor: 'pointer',
                        textShadow: '0px 0px 10px #000000',
                      },
                    }}
                  >
                    {'\u2022'} Assets Management System (AMS)
                  </Link>
                </Typography>
                <Typography
                  variant='body2'
                  component='span'
                  gutterBottom
                  align='left'
                >
                  <Link
                    href='/wms'
                    sx={{
                      textDecoration: 'none',
                      color: 'darkgrey',
                      '&:hover': {
                        cursor: 'pointer',
                        textShadow: '0px 0px 10px #000000',
                      },
                    }}
                    onClick={(e) => {
                      e.preventDefault()
                    }}
                  >
                    {'\u2022'} Warehouse Management System (WMS) (Coming Soon)
                  </Link>
                </Typography>
              </Box>
              <Box
                display={'flex'}
                flexDirection={'column'}
                gap={1}
                justifyContent={'flex-start'}
              >
                <Typography
                  variant='h6'
                  component='h2'
                  gutterBottom
                  align='left'
                >
                  Customized Solutions
                </Typography>
                <Typography
                  variant='body2'
                  component='span'
                  gutterBottom
                  align='left'
                >
                  <Link
                    href='/customize-solution'
                    sx={{
                      textDecoration: 'none',
                      color: 'inherit',
                      '&:hover': {
                        cursor: 'pointer',
                        textShadow: '0px 0px 10px #000000',
                      },
                    }}
                  >
                    {'\u2022'} Tailor-make Your Own Solution
                  </Link>
                </Typography>
                <Typography
                  variant='body2'
                  component='span'
                  gutterBottom
                  align='left'
                >
                  <Link
                    href='/self-host-solution'
                    sx={{
                      textDecoration: 'none',
                      color: 'inherit',
                      '&:hover': {
                        cursor: 'pointer',
                        textShadow: '0px 0px 10px #000000',
                      },
                    }}
                  >
                    {'\u2022'} Business Self-host Solution
                  </Link>
                </Typography>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} sm={3}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                {/* Connection Map */}
                <Box
                  display='flex'
                  flexDirection='row'
                  gap={1}
                  justifyContent={'flex-start'}
                >
                  <Box
                    display={'flex'}
                    flexDirection={'column'}
                    gap={1}
                    justifyContent={'flex-start'}
                  >
                    <Typography
                      variant='subtitle1'
                      component='sub'
                      gutterBottom
                      align='left'
                    >
                      Got ideas need bring to life?
                    </Typography>
                    <Typography variant='body2' align='left'>
                      <Link
                        href={'mailto:hello@whateverworks.cc'}
                        underline='hover'
                        sx={{
                          color: 'inherit',
                          fontWeight: 'bold',
                          display: 'inline-flex',
                          alignItems: 'center',
                        }}
                      >
                        <span>{`Let's connect`}</span>
                        <MailOutline sx={{ ml: 1 }} />
                      </Link>{' '}
                      and explore how we can help you drive growth and
                      innovation.
                    </Typography>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12}>
                {/* Social Media */}
                <Divider />
                <Box
                  display='flex'
                  flexDirection='row'
                  gap={1}
                  justifyContent={'flex-start'}
                  color={'white'}
                >
                  <IconButton
                    color='inherit'
                    component='a'
                    href='https://www.linkedin.com/company/whatever-works-development-limited/'
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    <LinkedInIcon />
                  </IconButton>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <br />
        <Divider />
        <Box bottom={0} width={'100%'}>
          <Grid container spacing={2} alignItems='center'>
            <Grid item xs={12} sm={6}>
              <Box
                display='flex'
                justifyContent='flex-start'
                alignItems='center'
                flexDirection='row'
                gap={2}
              >
                <Box display='flex' justifyContent='flex-start'>
                  <img
                    src='./images/logo/white-font-logo.png'
                    width={'auto'}
                    height={'80px'}
                  />
                  {/* <img src='./images/logo/logo_b_n.png' width={'auto'} height={'80px'} /> */}
                </Box>
                <Box
                  display='flex'
                  justifyContent='flex-start'
                  flexDirection={'column'}
                >
                  <Typography variant='body2' component='span' ml={1}>
                    © 2023-2024 Whatever Works Dev. Co., Ltd.
                  </Typography>
                  <Typography variant='body2' component='span' ml={1}>
                    All rights reserved.
                  </Typography>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Box
                display='flex'
                justifyContent='flex-start'
                alignItems='center'
                flexDirection='row'
                gap={8}
              >
                <Typography
                  variant='body2'
                  component='span'
                  sx={{
                    cursor: 'pointer',
                  }}
                  onClick={() => {
                    window.location.href = './tos'
                  }}
                >
                  Terms of Service
                </Typography>
                <Typography
                  variant='body2'
                  component='span'
                  sx={{
                    cursor: 'pointer',
                  }}
                  onClick={() => {
                    window.location.href = './pp'
                  }}
                >
                  Privacy Policy
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Box>
  )
}

export default ProductCommonFooter
