import React, { CSSProperties, ReactNode, useEffect, useMemo } from 'react'
// import './SectionPageWrapper.css';
import { Layout, Space } from 'antd'
import { useLocation } from 'react-router-dom'
// import { getRandomColor } from '../../utils/randomTool';

type SectionPageWrapperProps = {
  children: ReactNode | ReactNode[]
  starter?: ReactNode
  terminator?: ReactNode
  sectionStyle?: CSSProperties | CSSProperties[]
}

const SectionPageWrapper: React.FC<SectionPageWrapperProps> = ({
  children,
  starter,
  terminator,
  sectionStyle,
}) => {
  // Hooks
  const location = useLocation()

  // LOCAL STATES
  const [activeIndex, setActiveIndex] = React.useState(starter ? 0 : 1)

  // LOCAL REFS
  const containerRef = React.useRef<HTMLDivElement>(null)
  const mouseWheelDeltaRef = React.useRef<number>(0)

  // LOCAL VARIABLES
  const containerStyle: CSSProperties = {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    overflow: 'auto',
    position: 'relative',
    flexGrow: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'transparent',
  }

  const defaultSectionStyle: CSSProperties = {
    width: '100%',
    height: '100%',
    backgroundColor: 'transparent',
    // backgroundColor: getRandomColor(),
  }

  // LOCAL FUNCTIONS
  const getStyle = (index: number) => {
    if (!sectionStyle) return defaultSectionStyle
    if (Array.isArray(sectionStyle)) {
      return {
        ...defaultSectionStyle,
        ...sectionStyle[index % sectionStyle.length],
      }
    }
    return {
      ...defaultSectionStyle,
      ...sectionStyle,
    }
  }

  const getIndexViaId = (id: string) => {
    if (starter) {
      const starterElement = starter as React.ReactElement
      if (starterElement.props.id === id) {
        return 0
      }
    }
    const allChildren = React.Children.toArray(children)
    const targetIndex =
      allChildren.findIndex((child) => {
        const childElement = child as React.ReactElement
        return childElement.props.id === id
      }) + 1

    if (terminator) {
      const terminatorElement = terminator as React.ReactElement
      if (terminatorElement.props.id === id) {
        return allChildren.length + 1
      }
    }
    return targetIndex ? targetIndex : 0
  }

  const movePrevious = () => {
    const targetIndex = activeIndex - 1
    if (targetIndex < (starter ? 0 : 1)) {
      return
    }
    setActiveIndex(targetIndex)
  }

  const moveNext = () => {
    const targetIndex = activeIndex + 1
    const totalNumberOfSections = React.Children.count(children)
    if (targetIndex > totalNumberOfSections + (terminator ? 1 : 0)) {
      return
    }
    setActiveIndex(targetIndex)
  }

  // RENDER FUNCTIONS
  const renderSectionIndicator = () => {
    // LOCAL VARIABLES
    const totalNumberOfSections = React.Children.count(children)

    // LOCAL FUNCTIONS
    const dotStyle = (isActive: boolean) => ({
      width: isActive ? '18px' : '10px',
      height: isActive ? '18px' : '10px',
      borderRadius: '50%',
      backgroundColor: isActive ? 'var(--brand-color)' : 'gray',
      transition: 'width 0.5s ease, height 0.5s ease, background-color 1s ease', // Add this line
    })

    const isCurrent = (index: number, target: number) => {
      return index === target
    }

    return (
      <div
        style={{
          position: 'absolute',
          top: '50%',
          right: '20px',
          transform: 'translateY(-50%)',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          gap: '10px',
          zIndex: 100,
        }}
      >
        {/* Starter Section */}
        <div
          key='section-indicator-dot-starter'
          style={dotStyle(isCurrent(activeIndex, 0))}
        />
        {/* Normal Child Sections */}
        {Array(totalNumberOfSections)
          .fill(0)
          .map((_, index) => {
            const isActive = isCurrent(activeIndex, index + 1)
            return (
              <div
                key={`section-indicator-dot-${index}`}
                style={dotStyle(isActive)}
              />
            )
          })}
        {/* Terminator Section */}
        <div
          key='section-indicator-dot-terminator'
          style={dotStyle(isCurrent(activeIndex, totalNumberOfSections + 1))}
        />
      </div>
    )
  }

  const renderSection = (targetIndex: number) => {
    // <Layout key={index} style={{ ...getStyle(index) }}>
    //   {child}
    // </Layout>
    const allChildren = React.Children.toArray(children)

    const defaultSectionContainerStyle: CSSProperties = {
      transition: 'opacity 0.5s ease',
      width: '100%',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      justifyContent: 'center',
      backgroundColor: 'transparent',
    }

    // if (targetIndex === 0) {
    //   return starter ?? null
    // } else if (targetIndex === allChildren.length + 1 || targetIndex === -1) {
    //   return terminator ?? null
    // } else if (targetIndex > 0 && targetIndex <= allChildren.length) {
    //   return (
    //     <Layout key={targetIndex} style={{ ...getStyle(targetIndex) }}>
    //       {allChildren[targetIndex - 1]}
    //     </Layout>
    //   )
    // } else {
    //   console.warn('SectionPageWrapper: Invalid activeIndex', targetIndex)
    //   return null
    // }
    const isCurrent = (index: number, target: number) => {
      return index === target
    }

    return (
      <>
        <Layout
          style={{
            ...defaultSectionContainerStyle,
            position: 'absolute',
            opacity: isCurrent(0, activeIndex) ? 1 : 0,
          }}
        >
          {starter ?? null}
        </Layout>
        {allChildren.map((child, index) => {
          const style: CSSProperties = {
            ...defaultSectionContainerStyle,
            ...getStyle(index),
            opacity: isCurrent(index + 1, activeIndex) ? 1 : 0,
            transition: 'opacity 0.5s ease',
            position: 'absolute',
            top: 0,
            left: 0,
            width: '-webkit-fill-available' /* For Chrome and Safari */,
            height: '100%',
          }

          return (
            <Layout key={index} style={style}>
              {child}
            </Layout>
          )
        })}
        <Layout
          style={{
            ...defaultSectionContainerStyle,
            opacity: isCurrent(allChildren.length + 1, activeIndex) ? 1 : 0,
          }}
        >
          {terminator ?? null}
        </Layout>
      </>
    )
  }

  // EVENT HANDLERS
  const handleScrollWheel = (e: WheelEvent) => {
    e.preventDefault()
    const delta = e.deltaY
    mouseWheelDeltaRef.current += delta

    if (mouseWheelDeltaRef.current >= 480) {
      moveNext()
      mouseWheelDeltaRef.current = 0
    } else if (mouseWheelDeltaRef.current <= -480) {
      movePrevious()
      mouseWheelDeltaRef.current = 0
    }
  }
  // SIDE EFFECTS
  useEffect(() => {
    const container = containerRef.current
    container?.addEventListener('wheel', handleScrollWheel)

    return () => {
      container?.removeEventListener('wheel', handleScrollWheel)
    }
  }, [handleScrollWheel])

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search)
    const targetId = searchParams.get('focus')

    const targetIndex = targetId ? getIndexViaId(targetId) : 0
    setActiveIndex(targetIndex)
  }, [location.search])
  return (
    <Layout style={containerStyle} ref={containerRef}>
      {renderSectionIndicator()}
      <Layout
        style={{
          width: '100%',
          height: '100%',
          flexGrow: 1,
          overflow: 'auto',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: 'transparent',
        }}
      >
        {renderSection(activeIndex)}
      </Layout>
    </Layout>
  )
}

export default SectionPageWrapper
